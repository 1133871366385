import React, {useEffect, useState} from 'react';
import "./ContentText.scss";
import ExternalContent from "./ExternalContent/ExternalContent";
import ContentAudio from "../ContentAudio/ContentAudio";
import ContentVideo from "../ContentVideo/ContentVideo";
import ContentSpotify from "../ContentSpotify/ContentSpotify";
import {Link} from "react-router-dom";

const ContentText = ({content}) => {
  const [data, setData] = useState();
  
  const URL_TO_FETCH =
    `https://2bpdqf0iwl.execute-api.sa-east-1.amazonaws.com/default/can-i-render-an-iframe?url=${content?.content?.origin_url}`;
  
  useEffect(() => {
    fetch(URL_TO_FETCH)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw response;
      })
      .then(data => {
        setData(data)
      })
      .catch(error => {
        return error
      })
  }, [setData, URL_TO_FETCH]);
  
  return (
    <div className="ContentText">
      {content?.content?.spotify_playlist_url && <ContentSpotify spotifyURL={content?.content?.spotify_playlist_url} />}
      {content?.content?.anchor_redirect_url &&
      <a className="anchor-button" target="_blank" rel="noreferrer" href={content?.content?.anchor_redirect_url}>
        <button/>
      </a>}
      {content?.content?.references &&
      <div className="references-link">
        <p>Referências:</p>
        {content?.content?.references?.map((item, index) => (
          <div>
            <a id={index} target="_blank" rel="noreferrer" href={item.link} >{item.name.split(":")}</a>
          </div>
        ))}
      </div>}
      {content?.content?.video_url && <ContentVideo videoURL={content?.content?.video_url}/>}
      {content?.platform === "LUNE" && <ContentAudio audioURL={content?.content?.audio_url} />}
      {content?.content?.origin_url && data?.can ? <div className="url-container">
          <ExternalContent url={content?.content?.origin_url} />
          <p>Link original: <Link to={{pathname: content?.content?.origin_url}}
                                  target="_blank">{content?.content?.origin_url}</Link>
          </p>
        </div>
        : <div className="button-container">
          <Link to={{pathname: content?.content?.origin_url}} target="_blank"><button>Link do conteúdo</button></Link>
        </div>}
      {content?.content?.address && <strong dangerouslySetInnerHTML={{__html: content?.content?.address}}/>}
      <div dangerouslySetInnerHTML={{__html: content && content.content.text}} />
    </div>
  );
}

export default ContentText;
