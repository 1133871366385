import {
  GET_EMAIL_TEMPLATE_REQUEST,
  GET_EMAIL_TEMPLATE_REQUEST_FAILED,
  GET_EMAIL_TEMPLATE_REQUEST_SUCCEEDED,
  POST_EMAIL_REQUEST,
  POST_EMAIL_REQUEST_FAILED,
  POST_EMAIL_REQUEST_SUCCEEDED
} from "../constants/email";

export const getTemplateEmailRequest = () => ({
  type: GET_EMAIL_TEMPLATE_REQUEST,
});

export const getTemplateEmailRequestSucceeded = json => ({
  type: GET_EMAIL_TEMPLATE_REQUEST_SUCCEEDED,
  data: json
});

export const getTemplateEmailRequestFailed = json => ({
  type: GET_EMAIL_TEMPLATE_REQUEST_FAILED,
  error: json
});

export const postEmailRequest = () => ({
  type: POST_EMAIL_REQUEST,
});

export const postEmailRequestSucceeded = json => ({
  type: POST_EMAIL_REQUEST_SUCCEEDED,
  data: json
});

export const postEmailRequestFailed = json => ({
  type: POST_EMAIL_REQUEST_FAILED,
  error: json
});
