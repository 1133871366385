import React from 'react';
import './SearchTagComment.scss';

const SearchTagComment = ({item, onClick}) => {
  return (
    <div className="SearchTagComment" onClick={onClick}>
      <p>{item && item.name}</p>
    </div>
  );
};

export default SearchTagComment;
