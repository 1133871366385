import {
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_REQUEST_FAILED,
  ADD_ADMIN_REQUEST_SUCCEEDED,
  GET_ADMIN_REQUEST,
  GET_ADMIN_REQUEST_FAILED,
  GET_ADMIN_REQUEST_SUCCEEDED,
} from "../constants/admin";

export const admins = (
  state = { isFetching: false, data: { items: [] }, error: {}, }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_ADMIN_REQUEST:
    case ADD_ADMIN_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_ADMIN_REQUEST_SUCCEEDED:
    case ADD_ADMIN_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_ADMIN_REQUEST_FAILED:
    case ADD_ADMIN_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
