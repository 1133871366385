import React, {useEffect} from 'react';
import {
  Grid,
} from "@material-ui/core";
import { getMashieDetails } from "../../../../redux/actions/mashie";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReportLowestAndHighest from "../ReportLowestAndHighest/ReportLowestAndHighest";
import AverageReport from "../AverageReport/AverageReport";
import ByArea from "../ByArea/ByArea";
import {newMashie} from "../mashie";
import Snapshot from "../Snapshot/Snapshot";
import "./MashieReport.scss";

const MashieReport = () => {
  const { id } = useParams();
  const dispatch = useDispatch()
  const mashieDetailsStore = useSelector(state => state.mashie.data)

  useEffect(() => {
    dispatch(getMashieDetails(id))
  }, [dispatch])

  return (
    <Grid container direction="column">
      <div className='container-reports'>
        <AverageReport mashieDetailsStore={mashieDetailsStore} />
        <ReportLowestAndHighest mashieDetailsStore={mashieDetailsStore} />
      </div>
      <ByArea mashieDetailsStore={mashieDetailsStore} />
      <Snapshot mashieDetailsStore={newMashie(mashieDetailsStore)} />
    </Grid>
  );
};

export default MashieReport;
