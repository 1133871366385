import React from 'react';
import "./UserContent.scss";
import ContentText from "../ContentText/ContentText";
import ContentTagList from "../ContentTagList/ContentTagList";

const UserContent = ({content}) => {

  return (
    <div className="UserContent">
      <div className="title-content">
        <h1>{content?.title}</h1>
        <p>por {content?.author?.username}</p>
      </div>
      <ContentText content={content}/>
      <ContentTagList tags={content?.tags}/>
    </div>
  );
};

export default UserContent;
