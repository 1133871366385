import { Paper, TextField, Button, Grid } from "@material-ui/core";
import React from "react";

const Input = ({ value, setValue, handleSend, text, input }) => {
  return (
    <Grid container style={{ marginTop: 50 }}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: 15 }}>
          <h3 style={{ marginLeft: 10 }}>Adicione {text}</h3>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label={input}
              variant="outlined"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{ width: 290, marginRight: 15, marginLeft: 10 }}
            />
            <Button variant="contained" color="primary" size="large" onClick={handleSend}>
              Enviar
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Input;
