import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";
import {getItemFromStorage} from "../../helper/storage";
import {AUTH_TOKEN} from "../../constants/local";

export const apiGetCourses = (token, filters) => {
  return authorizedFetch(urlFilters(`${baseUrl}/course/courses`, filters), {
    method: "GET",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};


export const apiCreateCourse = (token, payload) => {
  return authorizedFetch(`${baseUrl}/course/course`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};

export const apiGetCourseID = (token, id) => {
  return authorizedFetch(`${baseUrl}/course/course/${id}`, {
    method: "GET",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};

export const apiUpdateCourse = (token, payload, id) => {
  return authorizedFetch(`${baseUrl}/course/course/${id}`, {
    method: "PATCH",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};


export const apiDeleteCourse = (id) => {
  const token = getItemFromStorage(AUTH_TOKEN);
  return authorizedFetch(`${baseUrl}/course/course/${id}`, {
    method: "DELETE",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};
