import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import {
  contentRequest,
  contentRequestFailed,
  contentRequestSucceeded,
  createContentRequest,
  createContentRequestFailed,
  createContentRequestSucceeded,
  deleteContentRequest,
  deleteContentRequestFailed,
  deleteContentRequestSucceeded,
  updateContentRequest,
  updateContentRequestFailed,
  updateContentRequestSucceeded,
} from "../action-creators/contents";
import { apiCreateContent, apiDeleteContents, apiGetContents, apiUpdateContents } from "../api/contents";

const requestGetContent = (filters) => (dispatch) => {
  dispatch(contentRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiGetContents(token, filters)
    .then((json) => {
      dispatch(contentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(contentRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getContent = (filters) => (dispatch) => dispatch(requestGetContent(filters));

const requestDeleteContent = (id) => (dispatch) => {
  dispatch(deleteContentRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiDeleteContents(token, id)
    .then((json) => {
      dispatch(deleteContentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(deleteContentRequestFailed(json));
      return Promise.reject(json);
    });
};

export const deleteContent = (id) => (dispatch) => dispatch(requestDeleteContent(id));

const requestUpdateContent = (id, payload) => (dispatch) => {
  dispatch(updateContentRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiUpdateContents(token, id, payload)
    .then((json) => {
      dispatch(updateContentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(updateContentRequestFailed(json));
      return Promise.reject(json);
    });
};

export const updateContent = (id, payload) => (dispatch) => dispatch(requestUpdateContent(id, payload));

const requestCreateContent = (payload) => (dispatch) => {
  dispatch(createContentRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiCreateContent(token, payload)
    .then((json) => {
      dispatch(createContentRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(createContentRequestFailed(json));
      return Promise.reject(json);
    });
};

export const createContent = (payload) => (dispatch) => dispatch(requestCreateContent(payload));
