import React, { useState } from "react";
import Logo from "../../assets/img/logo-mastertech-mini.png";
import { TextField, Card, Button, FormControl, OutlinedInput, InputAdornment, IconButton, InputLabel } from "@material-ui/core";
import "./SignUp.scss";
import { ReactComponent as LockFreeIcon } from "../../assets/icons/lock_open.svg";
import { Link } from "react-router-dom";
import {useDispatch} from "react-redux";
import { userSignUp } from "../../redux/actions/user";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
  
  const handleSignUp = () => {
    const payload = {
      name,
      email,
      username,
      password,
    };
    dispatch(userSignUp(payload))
    .then(() => alert('Cadastro realizado com sucesso, aguarde liberação de acesso'))
    .catch(() => {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000)
    })
  };

  return (
    <section className="SignUp">
      <img src={Logo} alt="logo Mastertech" className="logo" />
      <Card className="container">
        <span className="rounded">
          <LockFreeIcon />
        </span>
        <p className="innerText">Faça seu cadastro</p>
        <p className={error ? "errorMessageActive" : "errorMessage"}>
            Já existe um usuário com esse email no sistema.
        </p>
        <TextField
          id="outlined-basic"
          label="Nome"
          variant="outlined"
          className="inputField"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          className="inputField"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Usuário"
          variant="outlined"
          className="inputField"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FormControl variant="outlined"style={{width: '100%', margin: 10}}>
          <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            label="Senha"
            variant="outlined"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <span className="tip">*Sua senha deve conter no mínimo 6 dígitos</span>
        <Button variant="contained" className="btn" onClick={handleSignUp}>
          CADASTRAR
        </Button>
        <div className="login">
          <p>Já tem conta? </p>
          <Link to="/login" className="cta">Acesse aqui</Link>
        </div>
      </Card>
    </section>
  );
};

export default SignUp;
