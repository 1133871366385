export const GET_COURSES_REQUEST = "GET_COURSES_REQUEST";
export const GET_COURSES_REQUEST_SUCCEEDED = "GET_COURSES_REQUEST_SUCCEEDED";
export const GET_COURSES_REQUEST_FAILED = "GET_COURSES_REQUEST_FAILED";

export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_REQUEST_SUCCEEDED = "CREATE_COURSE_REQUEST_SUCCEEDED";
export const CREATE_COURSE_REQUEST_FAILED = "CREATE_COURSE_REQUEST_FAILED";

export const GET_COURSE_REQUEST = "GET_COURSE_REQUEST";
export const GET_COURSE_REQUEST_SUCCEEDED = "GET_COURSE_REQUEST_SUCCEEDED";
export const GET_COURSE_REQUEST_FAILED = "GET_COURSE_REQUEST_FAILED";

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_REQUEST_SUCCEEDED = "UPDATE_COURSE_REQUEST_SUCCEEDED";
export const UPDATE_COURSE_REQUEST_FAILED = "UPDATE_COURSE_REQUEST_FAILED";
