import React, { useState } from 'react';
import { Card, FormControl, Input, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

const AddQuestions = ({ questions, setQuestions, starterQuestionList, setStarterQuestionList }) => {
  const [listOptions, setListOptions] = useState({});
  const [valueInput, setValueInput] = useState({});
  const [optionName, setOptionName] = useState("");

  const removeOption = (name, index) => {
    setOptionName(name)
    listOptions[index].splice(listOptions[index].indexOf(name), 1)
  }
  const handleChangeQuestions = (value, index, keyName) => {
    const questionsCopy = [...starterQuestionList];
    if (questionsCopy[index]) {
      questionsCopy[index][keyName] = value
    } else {
      const question = {}
      question[keyName] = value
      questionsCopy.push(question)
    }
    setStarterQuestionList(questionsCopy)
  }

  const handleCreateOption = (value, index) => {
    if (!valueInput[index]) {
      valueInput[index] = value
    }

    valueInput[index] = value

    if (valueInput[index].includes('","')) {
      const noSpecialCharacters = valueInput[index]
      if (noSpecialCharacters.length < 5) {
        alert("O mínimo é 5 caracteres")
       } else {
        valueInput[index] = ""
        if (!listOptions[index]) {
          listOptions[index] = []
        }
        listOptions[index].push(noSpecialCharacters.replace(/","/g, ""))
      }
    }
  }

  const handleDeleteQuestion = (value) => {
    setQuestions(questions.filter((element, index) => index !== value))
    setStarterQuestionList(starterQuestionList.filter((element, index) => index !== value))
  }
  
  return (
    <div style={{ width: "100%" }}>
      {questions.map((manager, index) => {
        return (
          <div style={{ margin: "40px 0" }}>
            <Card
              style={{
                marginTop: "30px",
                display: "flex",
                alignItems: "flex-end",
                padding: "20px"
              }}>
              <TextField key={index}
                type="text"
                value={starterQuestionList[index]?.prompt || ""}
                onChange={(value) => handleChangeQuestions(value.target.value, index, "prompt")}
                placeholder="Pergunta"
                style={{ width: "90%" }}
              />
              <FormControl
                style={{ width: 200, marginRight: 30 }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo de resposta
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Tipo de resposta"
                  value={starterQuestionList[index]?.type || ""}
                  onChange={(value) => handleChangeQuestions(value.target.value, index, "type")}
                >
                  <MenuItem value={"STRING"}>Texto</MenuItem>
                  <MenuItem value={"INTEGER"}>Numérico</MenuItem>
                  <MenuItem value={"DATE"}>Data</MenuItem>
                  <MenuItem value={"EMAIL"}>E-Mail</MenuItem>
                  <MenuItem value={"PHONE"}>Telefone</MenuItem>
                  <MenuItem value={"CPF"}>CPF</MenuItem>
                  <MenuItem value={"LIST"}>LISTA</MenuItem>
                  <MenuItem value={"RADIO"}>SELECT</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                style={{ background: "red", color: "white" }}
                startIcon={<DeleteIcon />}
                onClick={() => handleDeleteQuestion(index)}
              >
                Apagar
              </Button>
            </Card>
            {starterQuestionList[index]?.type === "LIST" || starterQuestionList[index]?.type === "RADIO" ?
              <div style={{
                borderRadius: "0 0 20px 20px",
                padding: 20, display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-evelyn",
                border: "2px solid black",
                borderTop: "0px"
              }}>
                <div style={{ width: 300 }}>
                  <Input
                    style={{ width: 300 }}
                    value={valueInput[index]}
                    id="campo"
                    type="text"
                    placeholder="Adicionar opção"
                    onChange={(e) => {
                      handleCreateOption(e.target.value, index)
                      handleChangeQuestions(listOptions[index], index, "values")
                    }} />
                  <p>Insira uma vírgula entre aspas <strong>(",")</strong> depois de cada opção</p>
                </div>
                <div style={{ width: "80%", flexWrap: "wrap" }}>
                  {listOptions?.[index]?.map((item, key) => {
                    return <Button style={{ textTransform: "none", minWidth: 100, height: 50, margin: 20 }} key={key} variant="contained"
                      onClick={() => {
                        removeOption(item, index)
                        handleChangeQuestions(listOptions[index], index, "values")
                      }}
                    >{item}</Button>
                  })}
                </div>
              </div> : delete starterQuestionList[index]?.values}
          </div>
        )
      })}
    </div>
  );
};

export default AddQuestions;
