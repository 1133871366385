import { apiAddModule, apiUpdateModule} from "../api/modules";
import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";

import {
  createModuleRequest,
  createModuleRequestSucceeded,
  createModuleRequestFailed,
  updateModuleRequest,
  updateModuleRequestSucceeded,
  updateModuleRequestFailed
} from '../action-creators/modules'


const requestCreateModule = (payload, courseID) => dispatch => {
  dispatch(createModuleRequest());
  return apiAddModule(payload, courseID)
    .then((json) => {
      dispatch(createModuleRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(createModuleRequestFailed(json));
      return Promise.reject(json);
    });
};

export const createModule = (payload, courseID) => (dispatch) => dispatch(requestCreateModule(payload, courseID));

const requestUpdateModule = (payload, moduleID) => dispatch => {
  dispatch(updateModuleRequest());
  return apiUpdateModule(payload, moduleID)
    .then((json) => {
      dispatch(updateModuleRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(updateModuleRequestFailed(json));
      return Promise.reject(json);
    });
};

export const updateModule = (payload, moduleID) => (dispatch) => dispatch(requestUpdateModule(payload, moduleID));
