import {
  USER_CREATE_REQUEST,
  USER_CREATE_REQUEST_FAILED,
  USER_CREATE_REQUEST_SUCCEEDED,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_REQUEST_FAILED,
  USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED,
  USER_NEW_PASSWORD_REQUEST,
  USER_NEW_PASSWORD_REQUEST_SUCCEEDED,
} from "../constants/user";

export const userCreateRequest = () => ({
  type: USER_CREATE_REQUEST,
});

export const userCreateRequestSucceeded = (json) => ({
  type: USER_CREATE_REQUEST_SUCCEEDED,
  data: json,
});

export const userCreateRequestFailed = (json) => ({
  type: USER_CREATE_REQUEST_FAILED,
  error: json,
});

export const userForgotPasswordRequest = () => {
  return {
    type: USER_FORGOT_PASSWORD_REQUEST,
  };
};

export const userForgotPasswordRequestSucceeded = (json) => {
  return {
    type: USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED,
    data: json,
  };
};

export const userForgotPasswordRequestFailed = (json) => {
  return {
    type: USER_FORGOT_PASSWORD_REQUEST_FAILED,
    error: json,
  };
};

export const userNewPasswordRequest = () => {
  return {
    type: USER_NEW_PASSWORD_REQUEST,
  };
};

export const userNewPasswordRequestSucceeded = (json) => {
  return {
    type: USER_NEW_PASSWORD_REQUEST_SUCCEEDED,
    data: json,
  };
};

export const userNewPasswordRequestFailed = (json) => {
  return {
    type: USER_NEW_PASSWORD_REQUEST_SUCCEEDED,
    error: json,
  };
};
