import React from 'react';
import ReactDOM from 'react-dom';
import Notification from './Notification';

const showNotification = (message, autoClose = 6000) => {
  const notificationContainer = document.createElement('div');
  document.body.appendChild(notificationContainer);

  const closeNotification = () => {
    ReactDOM.unmountComponentAtNode(notificationContainer);
    document.body.removeChild(notificationContainer);
  };

  const notificationComponent = (
    <Notification message={message} duration={autoClose} />
  );

  ReactDOM.render(notificationComponent, notificationContainer);

  if (autoClose) {
    setTimeout(() => {
      closeNotification();
    }, autoClose);
  }
};

export default showNotification;
