export const LIST_MASHIES_REQUEST = "LIST_MASHIES_REQUEST";
export const LIST_MASHIES_REQUEST_SUCCEEDED = "LIST_MASHIES_REQUEST_SUCCEEDED";
export const LIST_MASHIES_REQUEST_FAILED = "LIST_MASHIES_REQUEST_FAILED";

export const GET_MASHIE_DETAILS_REQUEST = "GET_MASHIE_DETAILS_REQUEST";
export const GET_MASHIE_DETAILS_REQUEST_SUCCEEDED = "GET_MASHIE_DETAILS_REQUEST_SUCCEEDED";
export const GET_MASHIE_DETAILS_REQUEST_FAILED = "GET_MASHIE_DETAILS_REQUEST_FAILED";

export const CREATE_MASHIE_REQUEST = "CREATE_MASHIE_REQUEST";
export const CREATE_MASHIE_REQUEST_SUCCEEDED = "CREATE_MASHIE_REQUEST_SUCCEEDED";
export const CREATE_MASHIE_REQUEST_FAILED = "CREATE_MASHIE_REQUEST_FAILED";

export const GET_MASHIE_SUBMISSIONS_REQUEST = "GET_MASHIE_SUBMISSIONS_REQUEST";
export const GET_MASHIE_SUBMISSIONS_REQUEST_SUCCEEDED = "GET_MASHIE_SUBMISSIONS_REQUEST_SUCCEEDED";
export const GET_MASHIE_SUBMISSIONS_REQUEST_FAILED = "GET_MASHIE_SUBMISSIONS_REQUEST_FAILED";
