import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import { addAdminRequest, addAdminRequestFailed, addAdminRequestSucceeded, deleteAdminRequest, deleteAdminRequestFailed, deleteAdminRequestSucceeded, getAdminRequest, getAdminRequestFailed, getAdminRequestSucceeded } from "../action-creators/admin";
import { apiAddAdmin, apiDeleteAdmin, apiGetAdmin } from "../api/admin";

const requestGetAdmin = () => dispatch => {
  dispatch(getAdminRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiGetAdmin(token)
    .then(json => {
      dispatch(getAdminRequestSucceeded(json))
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(getAdminRequestFailed(json));
      return Promise.reject(json);
    })
}

export const getAdmin = () => dispatch => dispatch(requestGetAdmin());


const requestDeleteAdmin = (id) => dispatch => {
  dispatch(deleteAdminRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiDeleteAdmin(token, id)
    .then(json => {
      dispatch(deleteAdminRequestSucceeded(json))
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(deleteAdminRequestFailed(json));
      return Promise.reject(json);
    })
}

export const deleteAdmin = id => dispatch => dispatch(requestDeleteAdmin(id));


const requestAddAdmin = (payload) => dispatch => {
  dispatch(addAdminRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiAddAdmin(token, payload)
    .then(json => {
      dispatch(addAdminRequestSucceeded(json))
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(addAdminRequestFailed(json));
      return Promise.reject(json);
    })
}

export const addAdmin = payload => dispatch => dispatch(requestAddAdmin(payload));
