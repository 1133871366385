import {
  USER_CREATE_REQUEST,
  USER_CREATE_REQUEST_FAILED,
  USER_CREATE_REQUEST_SUCCEEDED,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_REQUEST_FAILED,
  USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED,
  USER_NEW_PASSWORD_REQUEST,
  USER_NEW_PASSWORD_REQUEST_SUCCEEDED,
  USER_NEW_PASSWORD_REQUEST_FAILED
} from "../constants/user";

export const userReducer = (
  state = { data: {}, isFetching: false, error: {} },
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case USER_CREATE_REQUEST:
      newState.isFetching = true;
      return newState;

    case USER_CREATE_REQUEST_SUCCEEDED:
      newState.data = { ...state.data };
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case USER_CREATE_REQUEST_FAILED:
      newState.error = action.error;
      newState.data = {};
      newState.isFetching = false;
      return newState;

    default:
      return state;
  }
};

export const newPassword = (state = { data: [], isFetching: false, error: {} }, action) => {
  let newState = { ...state };
  switch (action.type) {
    case USER_FORGOT_PASSWORD_REQUEST:
    case USER_NEW_PASSWORD_REQUEST:
      newState.isFetching = true;
      return newState;

    case USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED:
    case USER_NEW_PASSWORD_REQUEST_SUCCEEDED:
      newState.data = action.data;
      newState.isFetching = false;
      newState.error = {};
      return newState;

    case USER_FORGOT_PASSWORD_REQUEST_FAILED:
    case USER_NEW_PASSWORD_REQUEST_FAILED:
      newState.error = action.error;
      newState.isFetching = false;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
