import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow} from "./common";
import {getItemFromStorage} from "../../helper/storage";
import {AUTH_TOKEN} from "../../constants/local";

export const apiAddModule = (payload, courseID) => {
  const token = getItemFromStorage(AUTH_TOKEN);
  return authorizedFetch(`${baseUrl}/course/course/${courseID}/module`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};


export const apiUpdateModule = (payload, moduleID) => {
  const token = getItemFromStorage(AUTH_TOKEN);
  return authorizedFetch(`${baseUrl}/course/course/module/${moduleID}`, {
    method: "PATCH",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiDeleteModule = (id) => {
  const token = getItemFromStorage(AUTH_TOKEN);
  return authorizedFetch(`${baseUrl}/course/course/module/${id}`, {
    method: "DELETE",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};
