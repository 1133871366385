import {
  GET_PURCHASE_REQUEST,
  GET_PURCHASE_REQUEST_FAILED,
  GET_PURCHASE_REQUEST_SUCCEEDED,
} from "../constants/purchase";

export const getPurchaseRequest = () => ({
  type: GET_PURCHASE_REQUEST,
});

export const getPurchaseRequestSucceeded = (json) => ({
  type: GET_PURCHASE_REQUEST_SUCCEEDED,
  data: json,
});

export const getPurchaseRequestFailed = (json) => ({
  type: GET_PURCHASE_REQUEST_FAILED,
  error: json,
});
