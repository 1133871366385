import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import {
  getTemplateEmailRequest,
  getTemplateEmailRequestFailed,
  getTemplateEmailRequestSucceeded,
  postEmailRequest,
  postEmailRequestFailed,
  postEmailRequestSucceeded
} from "../action-creators/email";
import { apiGetTemplateEmail, apiPostEmail } from "../api/email";

const requestGetTemplateEmail = () => async dispatch => {
  dispatch(getTemplateEmailRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  try {
    const json = await apiGetTemplateEmail(token);
    dispatch(getTemplateEmailRequestSucceeded(json));
    return await Promise.resolve();
  } catch (json_error) {
    dispatch(getTemplateEmailRequestFailed(json_error));
    return await Promise.reject(json_error);
  }
}

export const getTemplate = () => dispatch => dispatch(requestGetTemplateEmail());

const requestPostEmail = (payload) => async dispatch => {
  dispatch(postEmailRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  try {
    console.log(payload)
    const json = await apiPostEmail(token, payload);
    dispatch(postEmailRequestSucceeded(json));
    return await Promise.resolve();
  } catch (json_error) {
    dispatch(postEmailRequestFailed(json_error));
    return await Promise.reject(json_error);
  }
}

export const postEmail = (payload) => dispatch => dispatch(requestPostEmail(payload));
