import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters } from "./common";

export const apiListMashies = (token) => {
  return authorizedFetch(`${baseUrl}/mashie/mashies?pageSize=50`, {
    method: "GET",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiGetMashieDetails = (token, code) => {
  return authorizedFetch(`${baseUrl}/mashie/mashie/${code}`, {
    method: "GET",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiDeleteMashie = (token, code) => {
  return authorizedFetch(`${baseUrl}/mashie/mashie/${code}`, {
    method: "DELETE",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiChangeMashieStatus = (token, code, newStatus) => {
  return authorizedFetch(`${baseUrl}/mashie/mashie/${code}`, {
    method: "PATCH",
    headers: buildHeaders(token),
    body: JSON.stringify({
      "active": newStatus
    })
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiCreateMashie = (token, payload) => {
  return authorizedFetch(`${baseUrl}/mashie/mashie`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiGetMashieSubmissions = (token, code, filters) => {
  return authorizedFetch(urlFilters(`${baseUrl}/mashie/mashie/${code}/submissions`, filters), {
    method: "GET",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};
