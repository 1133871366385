import {getItemFromStorage} from "../../helper/storage";
import {getRefreshToken, getToken, hasToken, tokenStillValid, updateToken} from "../../helper/loginStorage";
import {apiRefreshLogin} from "./login";

export const baseUrl = process.env.REACT_APP_ALEXANDRIA_API;

export const buildHeaders = (token) => {
  const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  }

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
}

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getResponseOrThrow = async response => {
  let responseText = await response.text();
  let parsedResponse = isJsonString(responseText) ? JSON.parse(responseText) : responseText;
  
  if (response.ok) {
    return parsedResponse;
  } else {
    throw parsedResponse;
  }
};

export const urlFilters = (url, filters) => {
  if (filters === undefined) {
    return url;
  }

  const filtersKeys = Object.keys(filters);

  filtersKeys.forEach(key => {
    const keyValue = filters[key];
    if (keyValue !== '') {
      const symbol = url.includes('?') ? '&' : '?';
      if (Array.isArray(keyValue)) {
        for(let value of keyValue) {
          url += `${symbol}${key}=${value}`
        }
      }else {
        url += `${symbol}${key}=${keyValue}`
      }
    }
  });

  return url;
}

let refreshPromise = undefined;
export const authorizedFetch = (url, init) => {
  return new Promise((resolve, reject) => {
    if (getItemFromStorage("ALEXANDRIA_AUTH_TOKEN")){
      if (!init.headers) {
        init.headers = {};
      }
      if (!tokenStillValid()) {
        if(!refreshPromise) {
          const refreshToken = getRefreshToken();
          refreshPromise = apiRefreshLogin(refreshToken);
          refreshPromise.then(data => {
            updateToken(data)
            refreshPromise = undefined;
          }).catch(() => {
            window.localStorage.clear()
            window.location.href = "/login";
          })
        }
        refreshPromise.then(() => {
          init.headers["Authorization"] = `Bearer ${getToken()}`
          fetch(url, init).then(resolve, reject)
        })
        return;
      }
      if(hasToken()) {
        init.headers["Authorization"] = `Bearer ${getToken()}`
      }
    }
    fetch(url, init).then(resolve, reject)
  })
};
