export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_REQUEST_SUCCEEDED = "USER_CREATE_REQUEST_SUCCEEDED";
export const USER_CREATE_REQUEST_FAILED = "USER_CREATE_REQUEST_FAILED";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED = "USER_FORGOT_PASSWORD_REQUEST_SUCCEEDED";
export const USER_FORGOT_PASSWORD_REQUEST_FAILED = "USER_FORGOT_PASSWORD_REQUEST_FAILED";

export const USER_NEW_PASSWORD_REQUEST = "USER_NEW_PASSWORD_REQUEST";
export const USER_NEW_PASSWORD_REQUEST_SUCCEEDED = "USER_NEW_PASSWORD_REQUEST_SUCCEEDED";
export const USER_NEW_PASSWORD_REQUEST_FAILED = "USER_NEW_PASSWORD_REQUEST_FAILED";
