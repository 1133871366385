import {
  CREATE_MODULE_REQUEST,
  CREATE_MODULE_REQUEST_SUCCEEDED,
  CREATE_MODULE_REQUEST_FAILED,
  UPDATE_MODULE_REQUEST,
  UPDATE_MODULE_REQUEST_SUCCEEDED,
  UPDATE_MODULE_REQUEST_FAILED,
} from "../constants/modules";

export const modules = (
  state = {
    isFetching: false,
    data: { items: [] },
    error: {},
  },
  action) => {
  let newState = { ...state };

  switch (action.type) {
    case CREATE_MODULE_REQUEST:
    case UPDATE_MODULE_REQUEST:
      newState.isFetching = true;
      return newState;

    case CREATE_MODULE_REQUEST_SUCCEEDED:
    case UPDATE_MODULE_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case CREATE_MODULE_REQUEST_FAILED:
    case UPDATE_MODULE_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
