import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiLogin = (payload) => {
  return fetch(`${baseUrl}/user/user/login`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json)
    })
};

export const apiGoogleLogin = payload => {
  return fetch(`${baseUrl}/user/user/googleauth`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  })
    .then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    });
};

export const apiRefreshLogin = (refreshToken) => {
  return fetch(`${baseUrl}/user/user/token/refresh`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${refreshToken}`
    },
  })
      .then(getResponseOrThrow)
      .then(json => {
        return Promise.resolve(json);
      })
};
