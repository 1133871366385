import {
  Button, Paper, Dialog, DialogContent,
  Table, TableBody, TableCell, TableHead,
  TablePagination, TableRow,
  Typography, DialogActions
} from '@material-ui/core';
import "./Purchase.scss";

import React, { useEffect, useState } from "react";
import Search from "../../Common/Search";
import DeleteIcon from '@material-ui/icons/PortraitOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from "../../../redux/actions/courses";
import { getAllPurchase } from "../../../redux/actions/purchase";
import { formatPrice } from '../../../utils/format';

const Purchase = () => {
  const dispatch = useDispatch();
  const [purchaseList, setPurchaseList] = useState([]);
  const purchases = useSelector((state) => state.purchase.data);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const handleOpen = (info) => {
    setInfos(info)
    setOpenModal(true)
  };
  const handleClose = () => {
    setInfos([])
    setOpenModal(false)
  };
  const [infos, setInfos] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    dispatch(getAllPurchase({ page: newPage + 1 }));
  };

  useEffect(() => {
    dispatch(getAllPurchase({ search: search }))
  }, [search])

  useEffect(() => {
    dispatch(getCourses());
    dispatch(getAllPurchase());
  }, []);

  useEffect(() => {
    setPurchaseList(purchases.items)
  }, [purchases.items])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxHeight: '70vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll'
  };

  const getQuestionPrompt = (questions, questionId) => {
    for (let question of questions) {
      if (question.id === questionId) {
        return question.prompt
      }
    }
    return questionId
  }

  return (
    <Paper style={{ marginTop: 100, width: "80%", padding: 20 }} elevation={3}>
      <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", marginBottom: 40, marginRight: 10 }}>
        <h2 style={{ padding: 10 }}>Lista de Compras</h2>
        <div style={{ display: "flex" }}>
          <Search placeholder={"Pesquisar compra"} survey value={search} setValue={setSearch} />
        </div>
      </div>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Identidade</TableCell>
            <TableCell align="center" >Detalhes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchaseList?.map((row, index) => (
            <TableRow key={index} hover>
              <TableCell>{row?.buyer?.email}</TableCell>
              <TableCell>{row?.buyer?.phone}</TableCell>
              <TableCell>{row?.buyer?.identifier}</TableCell>
              <TableCell align="center"><DeleteIcon onClick={() => handleOpen(row)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPage}
          count={purchases?.totalItems}
          page={page - 1}
          onChangePage={handleChangePage}
        />
        <Dialog
          open={openModal}
          onClose={handleClose}
          fullWidth
          maxWidth='md'
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogContent fullWidth sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h3">
              Dados do comprador
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "space-between" }}>
                <p><strong>Nome Completo: </strong> {infos.buyer?.name}</p>
                <p><strong>Telefone: </strong> {infos.buyer?.phone}</p>
                <p> <strong>Identidade: </strong> {infos.buyer?.identifier}</p>
              </div>
              <div>
                {!infos?.buyer?.address && <p><strong>Endereço: </strong>Sem endereço</p>}
                {infos?.buyer?.address &&
                  <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "space-between", alignItems: 'center' }}>
                    <h3 style={{ margin: 0 }}>Endereço: </h3>
                    <p><strong>Cidade:</strong> {[infos?.buyer?.address?.city]}</p>
                    <p><strong>Rua:</strong> {[infos?.buyer?.address?.street]},  {[infos?.buyer?.address?.number]}</p>
                    <p><strong>Bairro:</strong> {[infos?.buyer?.address?.locality]}</p>
                    <p><strong>CEP:</strong> {[infos?.buyer?.address?.postalCode]}</p>
                  </div>
                }
              </div>
            </Typography>
            <hr />
            <Typography id="modal-modal-title" variant="h4" component="h3" style={{ marginTop: 20 }}>
              Dados do pagamento
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <p><strong>Modo de pagamento: </strong> {infos.payment?.boleto_url ? "Boleto" : "Cartão de Crédito"}</p>
                <p><strong>Status do Pagamento: </strong>
                  {
                    {
                      'ERROR': 'ERRO NO PAGAMENTO',
                      'PAID': 'PAGO',
                      'WAITING': 'AGUARDANDO PAGAMENTO'
                    }[infos.payment?.status]
                  }
                </p>
                <p><strong>Valor total: </strong>{formatPrice(infos?.total_value/100)}</p>
              </div>
            </Typography>
            <hr />
            <Typography id="modal-modal-title" variant="h4" component="h4" style={{ marginTop: 20 }}>
              Produtos
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {infos?.products?.map((purchasedProduct) => {
                  return <div>
                    <h4>Curso: {purchasedProduct.name}</h4>
                    <h4>Quantidade: {purchasedProduct.amount}</h4>
                    <h4>Valor: {formatPrice(purchasedProduct.value/100)}</h4>
                    {
                      Array.apply(null, Array(purchasedProduct?.amount))?.map(function (x, i) {
                        return (
                          <div style={{ marginLeft: '30px' }}>
                            <p ><strong>ingresso nº {i + 1}</strong></p>
                            {purchasedProduct?.answerGroups[i]?.questions.map(question => {
                              return <>
                                <p>{getQuestionPrompt(purchasedProduct.questions, question.id)} = {question.answer}</p>
                              </>
                            })}
                            {(i + 1) === purchasedProduct.amount ? <></> : <p>-- / --</p>}
                          </div>
                        );
                      })
                    }
                    <div style={{ borderTop: "6px", backgroundColor: "grey", width: "100%", height: 1 }}></div>
                  </div>
                })}
              </Typography>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </Table>
    </Paper>
  );
}

export default Purchase;
