import React from 'react';
import './CheckboxWithDescription.scss'

const CheckboxWithDescription = ({ id, label, description, selected, onChange }) => {
  return (
    <div className={`CheckboxWithDescription ${selected ? "selected" : ""}`}>
      <label className="checkbox-label" htmlFor={id}>{label}</label>
      <input
        type="checkbox"
        id={id}
        checked={selected}
        style={{ background: 'white' }}
        onChange={() => onChange(id)}
      />
      <p>{description}</p>
    </div>
  );
};

export default CheckboxWithDescription;
