import React from 'react';
const EmailCSV = ({ emailCSV, setEmailCSV, setMetadataCSV }) => {

  function getFiles() {
    var files = document.getElementById("csvInput").files;
    setMetadataCSV(files[0])
  }
  return (
    <input
      accept=".csv"
      id="csvInput"
      multiple
      onChange={(e) => {
        getFiles()
      }}
      type="file"
    />
  );
};
export default EmailCSV;