import {
  Button, Paper, Dialog, DialogContent,
  Table, TableBody, TableCell, TableHead,
  TablePagination, TableRow,
  Typography, DialogActions, DialogTitle, DialogContentText
} from '@material-ui/core';
import "./Product.scss";

import React, { useEffect, useState } from "react";
import Search from "../../Common/Search";
import FormatAlignJustifyOutlinedIcon from '@material-ui/icons/FormatAlignJustifyOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from "../../../redux/actions/courses";
import { getAllProducts } from "../../../redux/actions/product";
import { formatPrice } from '../../../utils/format';
import { getCourse } from "../../../redux/actions/courses";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { apiDeleteProduct } from '../../../redux/api/product';
import { getItemFromStorage } from '../../../helper/storage';
import {AUTH_TOKEN} from "../../../constants/local";

const Product = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.data);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [infos, setInfos] = useState([]);
  const [infoCourse, setInfoCourse] = useState([]);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [removeContentID, setRemoveContentID] = useState("");
  const handleOpen = (info) => {
    setInfos(info)
    setOpenModal(true)
  };
  const handleClose = () => {
    setInfos([])
    setOpenModal(false)
  };

  const handleUpdateProduct = (id) => {
    history.push(`/ecommerce/produto/editar/${id}`)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage + 1);
    dispatch(getAllProducts({ page: newPage + 1 }));
  };

  useEffect(() => {
    dispatch(getAllProducts({ search: search }))
  }, [search])

  useEffect(() => {
    dispatch(getCourses());
    dispatch(getAllProducts());
  }, []);

  const handleClickOpen = (id) => {
    setOpenFeedback(true);
    setRemoveContentID(id);
  };

  const handleDelete = () => {
    apiDeleteProduct(getItemFromStorage(AUTH_TOKEN), removeContentID).then(() => {
      setOpenFeedback(false)
      window.location.reload()
    })
    alert('Produto removido com sucesso')
  }


  useEffect(() => {
    setInfoCourse([])
    infos.external_reference &&
      dispatch(getCourse(infos.external_reference?.substring(infos.external_reference?.lastIndexOf("/") + 1)))
        .then((course) => {
          setInfoCourse(course)
        })
  }, [infos]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxHeight: '70vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll'
  };

  return (
    <Paper style={{ marginTop: 100, width: "80%", padding: 20 }} elevation={3}>
      <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", marginBottom: 40, marginRight: 10 }}>
        <h2 style={{ padding: 10 }}>Lista de Produtos</h2>
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            style={{ height: 50, marginRight: 30 }}
            onClick={() => history.push("/ecommerce/criar-produto")}
          >
            Criar Produto
          </Button>
          <Search placeholder={"Pesquisar produto"} survey value={search} setValue={setSearch} />
        </div>
      </div>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Vagas</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Data final</TableCell>
            <TableCell align="center" >Detalhes</TableCell>
            <TableCell align="center" >Editar</TableCell>
            <TableCell align="center" >Apagar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.items?.map((row, index) => (
            <TableRow key={index} hover>
              <TableCell>{row?.name}</TableCell>
              <TableCell>{row?.supply ? row?.supply : 0}</TableCell>
              <TableCell>{formatPrice(row?.value/100)}</TableCell>
              <TableCell>{new Date(row?.stopSalesOn).toLocaleDateString()}</TableCell>
              <TableCell align="center"><FormatAlignJustifyOutlinedIcon onClick={() => handleOpen(row)} /></TableCell>
              <TableCell align="center"><EditIcon onClick={() => handleUpdateProduct(row.id)}/></TableCell>
              <TableCell align="center"><DeleteIcon onClick={() => handleClickOpen(row.id)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPage}
          count={products?.totalItems}
          page={page - 1}
          onChangePage={handleChangePage}
        />
        <Dialog
          open={openModal}
          onClose={handleClose}
          fullWidth
          maxWidth='md'
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogContent fullWidth sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h3">
              Dados do Produto
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div >
                <p><strong>Nome: </strong> {infos?.name}</p>
                <p><strong>ID: </strong> {infos?.id}</p>
                <p> <strong>Curso referente ao produto: </strong> {infoCourse?.name?.replace("//", "<wbr />")}</p>
                <p><strong>Data final: </strong>{new Date(infos?.stopSalesOn)?.toLocaleDateString()}</p>
                <p> <strong>Vagas: </strong> {infos?.supply ? infos?.supply : 0}</p>
                <p> <strong>Valor: </strong> {formatPrice(infos?.value/100)}</p>
              </div>
            </Typography>
            <hr />
            <Typography id="modal-modal-title" variant="h4" component="h4" style={{ marginTop: 20 }}>
              Perguntas
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {infos?.questions?.map((question, index) => {
                  return (
                    <div>
                      <h3>{question?.prompt}</h3>
                      <hr />
                    </div>
                  )
                })}
              </Typography>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>

        {openFeedback &&
          <Dialog
            open={openFeedback}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remover produto</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ textAlign: 'justify' }}>
                Apagar um produto significa que todos os dados do produto serão apagados.
                Isso inclui desde as configurações de perguntas a até mesmo a finalização de venda.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenFeedback(false)} color="primary">
                Não
              </Button>
              <Button onClick={() => handleDelete()} color="primary" autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>
        }
      </Table>
    </Paper>
  );
}

export default Product;
