import React from 'react';

const LatestQuestionAnswers = ({mashie}) => {
    const getFinishedAnswers = () => {
        let totalAnswers = [];
        {mashie?.answers.map(answer => {
            if (answer.status === "FINISHED") {
                totalAnswers.push(answer)
            }
        })}
        return totalAnswers
    }

    const extractCSVAnswers = (answers) => {
        let extractedAnswers = [];
        for (let i = 0; i < answers.length; i++) {
            extractedAnswers.push(extractCSVAnswer(answers[i]))
        }
        return extractedAnswers;
    }

    const extractCSVAnswer = (answer) => {
        // initialize
        const csvAnswer = {};
        csvAnswer.selectedSentences = [];
        csvAnswer.questionAnswers = [];

        // extract selectedSentences
        let lastScenario = answer.scenarios[answer.scenarios.length - 1]
        for (let i =0; i < lastScenario.comparisons.length; i++) {
            let sentenceId = lastScenario.comparisons[i].selectedSentenceId;
            csvAnswer.selectedSentences.push(mashie.getSentenceById(sentenceId).name)
        }

        // extract questionAnswers
        for (let i =0; i < answer.questionAnswers.length; i++) {
            let questionAnswer = answer.questionAnswers[i];
            csvAnswer.questionAnswers.push(questionAnswer.answer)
        }

        return csvAnswer;
    }

    const answerToCSV = (answer) => {
        let csvRow = "";
        for (let i = 0; i < answer.questionAnswers.length; i++) {
            if (csvRow.length !== 0) { csvRow += "; "}

            csvRow += answer.questionAnswers[i];
        }
        for (let i = 0; i < answer.selectedSentences.length; i++) {
            if (csvRow.length !== 0) { csvRow += "; "}

            csvRow += answer.selectedSentences[i];
        }
        return csvRow
    }

    const answersToCSV = (answers) => {
        let csv = "";

        // create header
        // add questions
        for (let i = 0; i < mashie.settings.questions.length; i++) {
            if (csv.length !== 0) { csv += "; "}

            csv += mashie.settings.questions[i].prompt
        }
        // add sentences
        for (let i = 1; i <= answers[0].selectedSentences.length; i++) {
            if (csv.length !== 0) { csv += "; "}

            csv += "Sentença " + i
        }

        csv += "\n"

        // add answers
        for(let answer of answers) {
            csv += answerToCSV(answer) + "\n"
        }
        return "data:text/csv;charset=utf-8," + csv
    }

    const downloadCSV = () => {
        let totalAnswers = getFinishedAnswers();
        let csvAnswers = extractCSVAnswers(totalAnswers)
        window.location.href = encodeURI(answersToCSV(csvAnswers));
    }

    return downloadCSV()
};

export default LatestQuestionAnswers;
