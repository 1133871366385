import { userCreateRequest, userCreateRequestFailed, userCreateRequestSucceeded, userForgotPasswordRequest, userForgotPasswordRequestFailed, userForgotPasswordRequestSucceeded, userNewPasswordRequest, userNewPasswordRequestFailed, userNewPasswordRequestSucceeded } from "../action-creators/user";
import { apiForgotPasswordRequest, apiNewPasswordRequest, apiUserSignUp } from "../api/user";

const requestUserSignUp = payload => dispatch => {
  dispatch(userCreateRequest());
  return apiUserSignUp(payload)
    .then(json => {
      dispatch(userCreateRequestSucceeded(json))
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(userCreateRequestFailed(json));
      return Promise.reject(json);
    })
}

export const userSignUp = payload => dispatch => dispatch(requestUserSignUp(payload));


const requestForgotPassword = (token, email) => dispatch => {
  dispatch(userForgotPasswordRequest());
  return apiForgotPasswordRequest(token, email)
    .then(json => {
      dispatch(userForgotPasswordRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(userForgotPasswordRequestFailed(json))
      return Promise.reject(json);
    });
};

export const forgotPassword = (token, email) => dispatch => dispatch(requestForgotPassword(token, email));

const requestNewPassword = (token, code, payload) => dispatch => {
  dispatch(userNewPasswordRequest());
  return apiNewPasswordRequest(token, code, payload)
    .then(json => {
      dispatch(userNewPasswordRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch(json => {
      dispatch(userNewPasswordRequestFailed(json))
      return Promise.reject(json);
    });
};

export const userSetNewPassword = (token, code, payload) => dispatch => dispatch(requestNewPassword(token, code, payload));
