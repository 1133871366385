import {
  CREATE_MODULE_REQUEST,
  CREATE_MODULE_REQUEST_SUCCEEDED,
  CREATE_MODULE_REQUEST_FAILED,
  UPDATE_MODULE_REQUEST,
  UPDATE_MODULE_REQUEST_SUCCEEDED,
  UPDATE_MODULE_REQUEST_FAILED
} from "../constants/modules";

export const createModuleRequest = () => ({
  type: CREATE_MODULE_REQUEST,
});

export const createModuleRequestSucceeded = (json) => ({
  type: CREATE_MODULE_REQUEST_SUCCEEDED,
  data: json
});

export const createModuleRequestFailed = (json) => ({
  type: CREATE_MODULE_REQUEST_FAILED,
  error: json
});

export const updateModuleRequest = () => ({
  type: UPDATE_MODULE_REQUEST,
});

export const updateModuleRequestSucceeded = (json) => ({
  type: UPDATE_MODULE_REQUEST_SUCCEEDED,
  data: json
});

export const updateModuleRequestFailed = (json) => ({
  type: UPDATE_MODULE_REQUEST_FAILED,
  error: json
});
