import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import {
  addTagsRequest,
  addTagsRequestFailed,
  addTagsRequestSucceeded,
  getTagsRequest,
  getTagsRequestFailed,
  getTagsRequestSucceeded,
} from "../action-creators/tags";
import { apiAddTags, apiGetTags } from "../api/tags";

const requestGetTags = () => (dispatch) => {
  dispatch(getTagsRequest());
  return apiGetTags()
    .then((json) => {
      dispatch(getTagsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(getTagsRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getAllTags = () => (dispatch) => dispatch(requestGetTags());

const requestAddTags = (payload) => (dispatch) => {
  dispatch(addTagsRequest());
  return apiAddTags(payload)
    .then((json) => {
      dispatch(addTagsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(addTagsRequestFailed(json));
      return Promise.reject(json);
    });
};

export const addTags = (payload) => (dispatch) => dispatch(requestAddTags(payload));
