import { Grid, Paper, Typography } from "@material-ui/core";
import React from 'react';
import "./AnswerTableRowScenario.scss";

const AnswerTableRowScenario = ({ mashie, answer, scenario }) => {

  return (
    <Paper className={"AnswerTableRowScenario"} elevation={3}>
      <Typography variant={"h6"}>
        Cenário: {scenario?.order} ({scenario?.id})
      </Typography>
      <Grid container
        spacing={1}>
        {scenario && scenario.comparisons.map(comparison => {
          return <Grid className={"Comparison"} container spacing={2}>
            <Grid item md={6}
              direction={"row-reverse"}
            >
              <div
                style={{
                  backgroundColor: comparison.leftSentenceId === comparison.selectedSentenceId ? '#de1a1a' : 'black'
                }}
              >
                {mashie.getSentenceById(comparison.leftSentenceId).name}
              </div>
            </Grid>
            <Grid item md={6}>
              <div
                style={{
                  backgroundColor: comparison.rightSentenceId === comparison.selectedSentenceId ? '#de1a1a' : 'black'
                }}
              >
                {mashie.getSentenceById(comparison.rightSentenceId).name}
              </div>
              <span>
                {comparison.timeItTookToSelect}s
              </span>
            </Grid>
          </Grid>
        })}
      </Grid>
    </Paper>
  );
};

export default AnswerTableRowScenario;
