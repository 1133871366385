import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow} from "./common";

export const apiGetContentItem = (token, id) => {
  return authorizedFetch(`${baseUrl}/content/content/${id}`, {
    method: "GET",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};
