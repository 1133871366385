import {
  GET_EMAIL_TEMPLATE_REQUEST,
  GET_EMAIL_TEMPLATE_REQUEST_FAILED,
  GET_EMAIL_TEMPLATE_REQUEST_SUCCEEDED,
  POST_EMAIL_REQUEST,
  POST_EMAIL_REQUEST_FAILED,
  POST_EMAIL_REQUEST_SUCCEEDED
} from "../constants/email";

export const email = (
  state = { isFetching: false, data: { items: [] }, error: {}, }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_EMAIL_TEMPLATE_REQUEST:
    case POST_EMAIL_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_EMAIL_TEMPLATE_REQUEST_SUCCEEDED:
    case POST_EMAIL_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_EMAIL_TEMPLATE_REQUEST_FAILED:
    case POST_EMAIL_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
