import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow} from "./common";
import {getItemFromStorage} from "../../helper/storage";
import {AUTH_TOKEN} from "../../constants/local";

export const apiGetTags = () => {
  return authorizedFetch(`${baseUrl}/content/tags`, {
    method: "GET",
    headers: buildHeaders(),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiAddTags = (payload) => {
  const token = getItemFromStorage(AUTH_TOKEN);
  return authorizedFetch(`${baseUrl}/content/tags`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};
