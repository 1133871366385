const getMaxValueOnComparisons = (mashieData) => {
  let maxValue = 1;
  for (let comparisonOccurrences of mashieData.metrics.comparisonOccurrences) {
    for (let occurrences of comparisonOccurrences.occurrences) {
      if (occurrences.occurrences > maxValue) {
        maxValue = occurrences.occurrences
      }
    }
  }
  return maxValue;
}

const getComparisons = (mashieData, sentenceToCompare, sentenceToGetComparisons) => {
  for (let comparisonOccurrences of mashieData.metrics.comparisonOccurrences) {
    if (comparisonOccurrences.sentenceId === sentenceToCompare) {
      for (let occurrences of comparisonOccurrences.occurrences) {
        if (occurrences.sentenceId === sentenceToGetComparisons) {
          return occurrences.occurrences
        }
      }
    }
  }
  return 0;
}

const getSentenceById = (mashieData, sentenceId) => {
  for (let sentence of mashieData.sentences.sentences) {
    if (sentence.id === sentenceId) {
      return sentence
    }
  }
}

const getQuestionById = (mashieData, questionId) => {
  for (let question of mashieData.settings.questions) {
    if (question.id === questionId) {
      return question
    }
  }
}

export const convertToMashieInstance = (mashieData) => {
  mashieData.getComparisons = getComparisons.bind(this, mashieData)
  mashieData.getMaxValueOnComparisons = getMaxValueOnComparisons.bind(this, mashieData)
  mashieData.getSentenceById = getSentenceById.bind(this, mashieData)
  mashieData.getQuestionById = getQuestionById.bind(this, mashieData)
  return mashieData
}
