import React from 'react';
import {Card, TextField} from "@material-ui/core";

const AddSentences = ({sentencesList, listMetadata, sentences, setSentences}) => {

  const handleChangeQuestions = (value, index, keyName, isMetadata) => {
    const questionsCopy = [...sentences];
    if (questionsCopy[index]) {
      if (isMetadata) {
        questionsCopy[index]["metadata"] = {...questionsCopy[index]["metadata"], [keyName]: value}
      } else {
        questionsCopy[index][keyName] = value
      }
    } else {
      const question = {}
      if (isMetadata) {
        question["metadata"] = {...question["metadata"], [keyName]: value}
      } else {
        question[keyName] = value
      }
      questionsCopy.push(question)
    }
    setSentences(questionsCopy)
  }

  return (
    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
      {sentencesList.map((question, questionIndex) => {
        return (
          <Card style={{marginTop: "20px", padding: "40px"}}>
            <div key={questionIndex}
                 style={{display: "flex", alignItems: "flex-end"}}>
              <TextField key={questionIndex}
                         type="text"
                         label="Sentença"
                         variant="outlined"
                         style={{width: 400}}
                         value={sentences[questionIndex]?.name || ""}
                         onChange={(value) => handleChangeQuestions(value.target.value, questionIndex, "name")}
                         placeholder="Sentença"
              />
            </div>
            {listMetadata?.map((item, index) => {
              return (
                <div key={index}
                     style={{marginTop: "20px", display: "flex"}}>
                  <TextField style={{width: "400%"}}
                             type="text"
                             label={item}
                             variant="outlined"
                             value={sentences[questionIndex]?.metadata?.[item] || ""}
                             onChange={(value) => handleChangeQuestions(value.target.value, questionIndex, item, true)}
                             placeholder="Valor do metadado"
                  />
                </div>)
            })}
          </Card>
        )
      })}
    </div>
  );
};

export default AddSentences;
