import { Grid, LinearProgress, Paper, Tooltip, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AUTH_TOKEN } from "../../../../constants/local";
import { getItemFromStorage } from "../../../../helper/storage";
import { secondsToShortFormat } from "../../../../helper/time";
import { getMashieDetails, getSubmissions } from "../../../../redux/actions/mashie";
import { apiChangeMashieStatus, apiDeleteMashie } from "../../../../redux/api/mashie";
import CautionDelete from "../../../Common/CautionDelete/CautionDelete";
import { convertToMashieInstance } from "../../utils/mashie";
import ComparisonOccurrenceTable from "../ComparisonOccurrenceTable/ComparisonOccurrenceTable";
import AnswerTable from "../answerTable/AnswerTable/AnswerTable";
import DownloadReports from "../downloadReports/DownloadReports/DownloadReports";

const ViewMashie = () => {
  const { id } = useParams();

  const history = useHistory();
  const dispatch = useDispatch()
  const mashieDetailsStore = useSelector(state => state.mashie)
  const mashieSubmissions = useSelector(state => state.mashieSubmissions)
  const [isUpdating, setIsUpdating] = useState(false);

  let mashie = convertToMashieInstance(mashieDetailsStore.data)
  let mashieSubmission = convertToMashieInstance(mashieSubmissions.data)

  React.useEffect(() => {
    dispatch(getMashieDetails(id))
  }, [dispatch])

  const handleUpdateState = () => {
    setIsUpdating(true);
    apiChangeMashieStatus(getItemFromStorage(AUTH_TOKEN), mashie.code, !mashie.active).then(() => {
      setIsUpdating(false);
      dispatch(getMashieDetails(id))
    })
  }

  useEffect(() => {
    dispatch(getSubmissions(id))
  }, [id]);

  const getAnswerCount = (mashie) => {
    let progress = mashie.answerCount['in_progress'] || 0
    let finished = mashie.answerCount['finished'] || 0
    let total = progress + finished
    return `${finished}/${total}`
  }

  return (
    <Grid container style={{
      width: '100%'
    }}>
      {mashieDetailsStore.isFetching && <LinearProgress style={{ marginTop: 100, marginRight: 100, width: '100%' }} />}
      {!mashieDetailsStore.isFetching && mashieDetailsStore.data.code && <>
        <Paper style={{ marginTop: 100, marginRight: 100, padding: 20, width: '100%' }} elevation={3}>
          <Grid container>
            <Grid item md={6}>
              <Typography variant={"h4"}>
                {mashie.name}
              </Typography>
              <Typography variant={"caption"}>
                {mashie.code}
              </Typography>
            </Grid>
            <Grid container md={6} justify={"flex-end"} alignItems={"flex-start"}>
              <DownloadReports mashie={mashie} />
              <Button
                variant="contained"
                style={{ marginRight: 5 }}
                onClick={() => history.push("/report/mashie/" + mashie.code)}
                disabled={isUpdating}
              >
                Relatório
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: 5 }}
                startIcon={mashie.active ? <LockIcon /> : <LockOpenIcon />}
                onClick={handleUpdateState}
                disabled={isUpdating}
              >
                {isUpdating ? "Atualizando..." : mashie.active ? "Bloquear respostas" : "Permitir respostas"}
              </Button>
              <CautionDelete
                name={`mashie '${mashie.name}'`}
                deleteDescription={"Apagar um mashie significa que todos os dados do Mashie serão apagados. " +
                  "Isso inclui desde as configurações do Mashie a até mesmo as respostas enviadas."}
                deleteApi={() => apiDeleteMashie(getItemFromStorage(AUTH_TOKEN), mashie.code)}
                successCallback={() => history.push("/mashies")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{
            marginTop: 5
          }}>
            <Grid item md={4}>
              <Typography variant={"h6"}>
                Descrição:
              </Typography>
              <Typography variant={"body2"}>
                {mashie.description}
              </Typography>
              <Typography variant={"h6"} style={{ marginTop: 20 }}>
                Cor padrão:
              </Typography>
              {mashie.settings?.themeColor ? <div style={{ backgroundColor: mashie.settings?.themeColor, width: 100, height: 30 }} />
                : <p>Sem cor</p>}
              <Typography variant={"h6"} style={{ marginTop: 20 }}>
                Textos dos Cenários:
              </Typography>
              {mashie.settings?.scenarioPrompts.map((item, i) => {
                return <p>{item}</p>
              })}
              <Typography variant={"h6"} style={{ marginTop: 20 }}>
                Texto final:
              </Typography>
              {mashie.settings?.thankyouMessage}
            </Grid>
            <Grid item md={4}>
              <Typography variant={"h5"}>
                Métricas:
              </Typography>
              <Typography variant={"body1"}>
                - <strong>{mashie.sentences.sentences.length}</strong> sentenças carregadas
                com <strong>{mashie.sentences.metadataNames.length}</strong> metadado(s) cada
              </Typography>
              <Tooltip
                title={
                  <React.Fragment>
                    {mashie.settings.questions.map(question => {
                      return <Typography variant="body1">- {question.prompt}</Typography>
                    })}
                  </React.Fragment>
                }
              >
                <Typography variant={"body1"}>
                  - <strong>{mashie.settings.questions.length}</strong> perguntas customizadas configuradas
                </Typography>
              </Tooltip>

              <Typography variant={"body1"}>
                - Duração média de <strong>{secondsToShortFormat(mashie.metrics.estimatedDuration)}</strong>
              </Typography>
              <Typography variant={"body1"}>
                - <strong>{getAnswerCount(mashie)}</strong> respostas recebidas
              </Typography>

              <Typography variant={"h5"} style={{
                marginTop: 15
              }}>
                Configurações:
              </Typography>
              <Typography variant={"body1"}>
                - Pedir login? {mashie.settings.requireLogin ?
                  <span style={{ color: '#00ac00' }}>Sim</span> :
                  <span style={{ color: '#ff5a5f' }}>Não</span>}
              </Typography>
              <Typography variant={"body1"}>
                - Aceitando respostas? {mashie.active ?
                  <span style={{ color: '#00ac00' }}>Sim</span> :
                  <span style={{ color: '#ff5a5f' }}>Não</span>}
              </Typography>
              <Typography variant={"body1"}>
                - Tipo de Mashie: {mashie.kind === "CASUAL" ? "Casual" :
                  mashie.kind === "BALANCED" ?
                    "Balanceado" :
                    "Duplicado"}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <ComparisonOccurrenceTable mashie={mashie} width={300} height={200} />
            </Grid>
          </Grid>
        </Paper>
        <AnswerTable mashieSubmission={mashieSubmission} mashie={mashie} code={id} />
      </>}
    </Grid>
  );
};

export default ViewMashie;
