import React from 'react';
import {Grid, Tooltip, Typography} from "@material-ui/core";

const ComparisonOccurrenceTable = ({mashie, width, height}) => {

  const generateTable = () => {
    let maxValue = mashie.getMaxValueOnComparisons();
    let cubeWidth = width / mashie.sentences.sentences.length
    let cubeHeight = height / mashie.sentences.sentences.length

    const calculateOpacity = (sentenceToCompare, sentenceToGetComparisons) => {
      let opacity = mashie.getComparisons(sentenceToCompare.id, sentenceToGetComparisons.id) / maxValue;
      if (opacity === 0) {
        opacity = 0.1
      }
      return opacity
    }
    return <Grid container direction={'column'}>
      {mashie.sentences.sentences.map(sentenceToCompare => {
        return <Grid container direction={'row'}>
          {mashie.sentences.sentences.map(sentenceToGetComparisons => {
            return <Grid item style={{
              width: cubeWidth,
              height: cubeHeight,
            }}>
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography
                      variant="h6">{mashie.getComparisons(sentenceToCompare.id, sentenceToGetComparisons.id)} comparações
                      entre:</Typography>
                    <Typography variant="body1">- {mashie.getSentenceById(sentenceToCompare.id).name}</Typography>
                    <Typography
                      variant="body1">- {mashie.getSentenceById(sentenceToGetComparisons.id).name}</Typography>
                  </React.Fragment>
                }
              >
                <div style={{
                  backgroundColor: '#0aa500',
                  opacity: calculateOpacity(sentenceToCompare, sentenceToGetComparisons),
                  width: '100%',
                  height: '100%'
                }}/>
              </Tooltip>
            </Grid>
          })}
        </Grid>
      })}
    </Grid>
  }

  return (
    <div>
      <Typography variant={"h5"}>
        Distribuição de comparações:
      </Typography>
      {mashie && generateTable()}
    </div>
  );
};

export default ComparisonOccurrenceTable;
