import React from 'react';
import './ContentAudio.scss'
import ReactPlayer from "react-player";

const ContentAudio = ({audioURL}) => {

  return (
    <section className="ContentAudio">
      <ReactPlayer
        controls={true} url={audioURL} />
    </section>
  );
};

export default ContentAudio;
