import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiGetTemplateEmail = async (token) => {
  try {
    const response = await authorizedFetch(`${baseUrl}/user/mail/templates`, {
      method: "GET",
      headers: buildHeaders(token),
    });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (json_error) {
    return await Promise.reject(json_error);
  }
};

export const apiPostEmail = async (token, payload) => {
  let body = new FormData();
  for (let key in payload) {
    body.append(key, payload[key]);
  }

  return await authorizedFetch(`${baseUrl}/user/mail/batch`,
    {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`
      },
      body: body
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};
