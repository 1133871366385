import {
  ADD_TAGS_REQUEST,
  ADD_TAGS_REQUEST_FAILED,
  ADD_TAGS_REQUEST_SUCCEEDED,
  GET_TAGS_REQUEST,
  GET_TAGS_REQUEST_FAILED,
  GET_TAGS_REQUEST_SUCCEEDED,
} from "../constants/tags";

export const tags = (
  state = { isFetching: false, data: [], error: {} },
  action
) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_TAGS_REQUEST:
    case ADD_TAGS_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_TAGS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_TAGS_REQUEST_FAILED:
    case ADD_TAGS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    case ADD_TAGS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data.push(action.data);
      return newState;

    default:
      return newState;
  }
};
