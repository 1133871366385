import {
  CREATE_MASHIE_REQUEST,
  CREATE_MASHIE_REQUEST_FAILED,
  CREATE_MASHIE_REQUEST_SUCCEEDED,
  GET_MASHIE_DETAILS_REQUEST,
  GET_MASHIE_DETAILS_REQUEST_FAILED,
  GET_MASHIE_DETAILS_REQUEST_SUCCEEDED,
  GET_MASHIE_SUBMISSIONS_REQUEST,
  GET_MASHIE_SUBMISSIONS_REQUEST_FAILED,
  GET_MASHIE_SUBMISSIONS_REQUEST_SUCCEEDED,
  LIST_MASHIES_REQUEST,
  LIST_MASHIES_REQUEST_FAILED,
  LIST_MASHIES_REQUEST_SUCCEEDED,
} from "../constants/mashie";

export const listMashiesRequest = () => ({
  type: LIST_MASHIES_REQUEST,
});

export const listMashiesRequestSucceeded = json => ({
  type: LIST_MASHIES_REQUEST_SUCCEEDED,
  data: json
});

export const listMashiesRequestFailed = json => ({
  type: LIST_MASHIES_REQUEST_FAILED,
  error: json
});

export const getMashieDetailsRequest = () => ({
  type: GET_MASHIE_DETAILS_REQUEST,
});

export const getMashieDetailsRequestSucceeded = json => ({
  type: GET_MASHIE_DETAILS_REQUEST_SUCCEEDED,
  data: json
});

export const getMashieDetailsRequestFailed = json => ({
  type: GET_MASHIE_DETAILS_REQUEST_FAILED,
  error: json
});

export const createMashieRequest = () => ({
  type: CREATE_MASHIE_REQUEST,
});

export const createMashieRequestSucceeded = json => ({
  type: CREATE_MASHIE_REQUEST_SUCCEEDED,
  data: json
});

export const createMashieRequestFailed = json => ({
  type: CREATE_MASHIE_REQUEST_FAILED,
  error: json
});

export const getMashieSubmissionsRequest = () => ({
  type: GET_MASHIE_SUBMISSIONS_REQUEST,
});

export const getMashieSubmissionsRequestSucceeded = json => ({
  type: GET_MASHIE_SUBMISSIONS_REQUEST_SUCCEEDED,
  data: json
});

export const getMashieSubmissionsRequestFailed = json => ({
  type: GET_MASHIE_SUBMISSIONS_REQUEST_FAILED,
  error: json
});
