import {
  GET_COURSES_REQUEST,
  GET_COURSES_REQUEST_FAILED,
  GET_COURSES_REQUEST_SUCCEEDED,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_REQUEST_SUCCEEDED,
  CREATE_COURSE_REQUEST_FAILED,
  GET_COURSE_REQUEST,
  GET_COURSE_REQUEST_FAILED,
  GET_COURSE_REQUEST_SUCCEEDED,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_REQUEST_FAILED,
  UPDATE_COURSE_REQUEST_SUCCEEDED,
} from "../constants/courses";


export const courseRequest = () => ({
  type: GET_COURSES_REQUEST,
});

export const courseRequestSucceeded = (json) => ({
  type: GET_COURSES_REQUEST_SUCCEEDED,
  data: json,
});

export const courseRequestFailed = (json) => ({
  type: GET_COURSES_REQUEST_FAILED,
  error: json,
});

export const createCourseRequest = () => ({
  type: CREATE_COURSE_REQUEST,
});

export const createCourseRequestSucceeded = (json) => ({
  type: CREATE_COURSE_REQUEST_SUCCEEDED,
  data: json
});

export const createCourseRequestFailed = (json) => ({
  type: CREATE_COURSE_REQUEST_FAILED,
  error: json
});

export const courseIdRequest = () => ({
  type: GET_COURSE_REQUEST,
});

export const courseIdRequestSucceeded = (json) => ({
  type: GET_COURSE_REQUEST_SUCCEEDED,
  data: json,
});

export const courseIdRequestFailed = (json) => ({
  type: GET_COURSE_REQUEST_FAILED,
  error: json,
});

export const updateCourseRequest = () => ({
  type: UPDATE_COURSE_REQUEST,
});

export const updateCourseRequestSucceeded = (json) => ({
  type: UPDATE_COURSE_REQUEST_SUCCEEDED,
  data: json
});

export const updateCourseRequestFailed = (json) => ({
  type: UPDATE_COURSE_REQUEST_FAILED,
  error: json
});
