import React from 'react';
import {
  Button,
  Grid, LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {listMashies} from "../../redux/actions/mashie";
import SaveIcon from "@material-ui/icons/Save";

const ListMashies = () => {

  const history = useHistory();
  const dispatch = useDispatch()
  const mashieListStore = useSelector(state => state.mashie)

  React.useEffect(() => {
    dispatch(listMashies())
  }, [dispatch])

  const getAnswerCount = (mashie) => {
    let progress = mashie.answerCount['in_progress'] || 0
    let finished = mashie.answerCount['finished'] || 0
    let total = progress + finished
    return `${finished}/${total}`
  }

  return (
    <Paper style={{marginTop: 100, width: "80%", padding: 20}} elevation={3}>
      <Grid container justify={"space-between"}>
        <div style={{display: "flex", alignItems: "start", justifyContent: "space-between", width: "100%"}}>
          <Typography variant={"h4"}>
            Lista de mashies
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            style={{marginBottom: "40px"}}
            onClick ={() => history.push("/criar-mashie")}
          >
            Criar mashie
          </Button>
        </div>
      </Grid>
      {mashieListStore.isFetching && <LinearProgress/>}
      {!mashieListStore.isFetching && mashieListStore.data?.items?.length === 0 &&
      <span>Nenhum Mashie encontrado.</span>}
      {!mashieListStore.isFetching && mashieListStore.data?.items?.length > 0 && <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Codigo</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell align="center">Numero de respostas</TableCell>
            <TableCell align="center">Aceitando respostas</TableCell>
            <TableCell align="center">Acessar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mashieListStore.data.items.map(mashie => {
            return <TableRow hover>
              <TableCell>{mashie.code}</TableCell>
              <TableCell>{mashie.name}</TableCell>
              <TableCell width={100} align="center">{getAnswerCount(mashie)}</TableCell>
              <TableCell width={100} align="center">{mashie.active ? "Sim" : "Não"}</TableCell>
              <TableCell width={75} align="center"><VisibilityIcon
                onClick={() => history.push("/mashie/" + mashie.code)}/></TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>}
    </Paper>
  );
};

export default ListMashies;
