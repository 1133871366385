import React, {useEffect} from 'react';
import {CSVReader} from "react-papaparse";

const SentenceWithCSV = ({sentenceCSV, setSentenceCSV, setMetadataCSV}) => {

  const handleOnDrop = (data) => {
    if (sentenceCSV.length < 1) {
      const csvCopy = [...sentenceCSV];
      data.map((item, index) => {
        const cvs = {
          name: item.data["name"],
          metadata: item.data
        }
        delete cvs.metadata['name']
        csvCopy.push(cvs)
      })
      setSentenceCSV(csvCopy)
    } else {
      setSentenceCSV(sentenceCSV)
    }
  }

  const handleOnRemoveFile = () => {
    setSentenceCSV([])
    setMetadataCSV([])
  }

  useEffect(() => {
    sentenceCSV.length > 1 && sentenceCSV.map((item) => {
      setMetadataCSV(Object.keys(item?.metadata))
    })
  }, [sentenceCSV]);

  return (
    <div>
      <CSVReader
        onDrop={handleOnDrop}
        addRemoveButton
        onRemoveFile={handleOnRemoveFile}
        config={{header: true}}
        removeButtonColor='#FF0000'
      >
        <span>Solte o arquivo CSV aqui ou clique para fazer o upload.</span>
      </CSVReader>
    </div>
  );
};

export default SentenceWithCSV;
