import React, { useState, useEffect } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table, TableBody, TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import Gradient from '../../utils/gradient';

const Snapshot = ({ mashieDetailsStore }) => {
  const [questionId, setQuestionId] = useState("");
  const [report, setReport] = useState([]);

  useEffect(() => {
    if (questionId !== "") {
      setReport(generateReport(questionId))
    }
  }, [questionId])

  const buildColors = size => {
    const gradientArray = new Gradient();
    gradientArray.setColorGradient("#55EFC4", "#FFEAA7", "#FF7675");
    return gradientArray.setMidpoint(size).getColors().reverse()
  }

  const generateReport = questionId => {
    // protect against loading scenarios
    if (!mashieDetailsStore['answers']) {
      return []
    }

    let reportVariable = {
      rows: {},
      headers: [],
      colors: {}
    }

    let grouped = mashieDetailsStore.getGroupedAnswersByQuestionId(questionId)
    reportVariable.headers = Object.keys(grouped)
    let maxTotal = 0
    for (let groupedKey of Object.keys(grouped)) { // column
      let ranked = mashieDetailsStore.getSentenceRank(grouped[groupedKey])
      for (let rank of ranked) { // column VS row
        if (!reportVariable.rows[rank.sentenceId]) {
          // initialize sentence object with default total as 0
          reportVariable.rows[rank.sentenceId] = {Total: 0}
        }
        reportVariable.rows[rank.sentenceId][groupedKey] = rank.count
        // sum total for that sentence
        reportVariable.rows[rank.sentenceId]["Total"] = reportVariable.rows[rank.sentenceId]["Total"] + rank.count

        // stores the maximum displayed value to use on color generation
        if (reportVariable.rows[rank.sentenceId]["Total"] > maxTotal) {
          maxTotal = reportVariable.rows[rank.sentenceId]["Total"]
        }
      }

      // populate colors
      reportVariable.colors[groupedKey] = buildColors(ranked[0]['count'] + 1)
    }
    reportVariable.colors["Total"] = buildColors(maxTotal + 1)
    reportVariable.headers.push("Total")
    return reportVariable
  }

  return (
    <Paper style={{ margin: "40px 100px 40px 0", padding: 20, width: "80%" }} elevation={3}>
      <Grid container>
        <Grid style={{ display: "flex", padding: 20, width: "100%", justifyContent: "space-between" }}>

          <Typography style={{ margin: "10px 20px" }} variant={"h5"}>
            Snapshot
          </Typography>
          <div style={{ marginBottom: 30 }}>
            <FormControl
              style={{ width: 250, marginRight: 30 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Agrupar por
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Distribuição de Sentenças"
                onChange={(e) => setQuestionId(e.target.value)}
              >
                {mashieDetailsStore?.settings?.questions?.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>
                    {item.prompt}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          {report && <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Sentença</TableCell>
                {report?.headers?.map((header, index) => {
                  return <TableCell key={index}>{header}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {report?.rows
              && Object?.keys(report?.rows)?.sort((a, b) => {
                  return report.rows[b]['Total'] - report.rows[a]['Total']
                }).map((sentenceId, index) => {
                return <TableRow key={index} hover>
                  <TableCell>{mashieDetailsStore.getSentenceName(sentenceId)}</TableCell>
                  {report?.headers?.map((header, index) => {
                    console.log(report.colors[header])
                    return <TableCell
                    style={{ backgroundColor: report.colors[header][report.rows[sentenceId][header]] }}
                    key={index}>{report.rows[sentenceId][header]}</TableCell>
                  })}
                </TableRow>
              })}
            </TableBody>
          </Table>}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Snapshot;
