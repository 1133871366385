import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow} from "./common";

export const apiGetAdmin = (token) => {
  return authorizedFetch(`${baseUrl}/user/admins`, {
    method: "GET",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiDeleteAdmin = (token, id) => {
  return authorizedFetch(`${baseUrl}/user/admin/${id}`, {
    method: "DELETE",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiAddAdmin = (token, payload) => {
  return authorizedFetch(`${baseUrl}/user/admin`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(payload),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};
