import React, { useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';

const EditorText = ({ setContent, textComplete }) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText( "")));
  const [convertedContent, setConvertedContent] = useState("");
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }
  
  useEffect(() => {
    textComplete && setEditorState(EditorState.createWithContent(ContentState.createFromText(textComplete)))
  }, [textComplete]);
  
  
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }

  useEffect(() => {
    setContent(prevState => ({ ...prevState, content: { text: convertedContent } }))
  }, [convertedContent, setContent]);
  
  return (
      <div style={{border: "1px solid #80808024"}}>
        <Editor
          placeholder="Digite seu texto aqui"
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
        />
      </div>
  )
}

export default EditorText;
