import React, { useState } from "react";
import Logo from "../../../assets/img/logo-mastertech-mini.png";
import { TextField, Card, Button, CircularProgress } from "@material-ui/core";
import "./Password.scss";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "../../../redux/actions/user";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);

  const recoverPasswordRequest = () => {
    dispatch(forgotPassword(userData?.data?.token, { email: email }))
      .then(() => {
        setShowFeedback(true);
        setFeedbackMessage("email enviado com sucesso!");
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          history.push("redefinir-senha/codigo");
        }, 3000)
      })
      .catch((json) => {
        if (json.error === "Email does not found") {
          setFeedbackMessage("Email não encontrado");
        } else {
          setFeedbackMessage("Houve um problema na comunicação do nosso servidor");
        }
        setShowFeedback(true);
        setEmail("");
        setTimeout(() => {
          setFeedbackMessage(false);
        }, 3000);
      });
  };

  return (
    <section className="Password">
      <img src={Logo} alt="logo Mastertech" className="logo" />
      <Card className="container">
        <span className="rounded">
          <LockIcon />
        </span>
        <p className="subtitle">Esqueceu sua senha?</p>
        <p className="innerText">
          Insira seu email no campo abaixo e nós enviaremos um código para
          resetar sua senha.
        </p>
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          className="inputField"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <p className={showFeedback ? "errorMessageActive" : "errorMessage"}>{feedbackMessage}</p>
        {loading ? <CircularProgress /> : 
        <Button variant="contained" className="btn" onClick={recoverPasswordRequest}>
          ENVIAR
        </Button>
        }
      </Card>
    </section>
  );
};

export default ForgotPassword;
