import { apiCreateCourse, apiGetCourseID, apiGetCourses, apiUpdateCourse } from "../api/courses";
import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";

import {
  courseRequest,
  courseRequestFailed,
  courseRequestSucceeded,
  createCourseRequest,
  createCourseRequestSucceeded,
  createCourseRequestFailed,
  courseIdRequest,
  courseIdRequestFailed,
  courseIdRequestSucceeded,
  updateCourseRequest,
  updateCourseRequestSucceeded,
  updateCourseRequestFailed,
} from '../action-creators/courses'

const requestGetCourses = (filters) => dispatch => {
  dispatch(courseRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiGetCourses(token, filters)
    .then((json) => {
      dispatch(courseRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(courseRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getCourses = (filters) => (dispatch) => dispatch(requestGetCourses(filters));

const requestCreateCourse = (payload) => dispatch => {
  dispatch(createCourseRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiCreateCourse(token, payload)
    .then((json) => {
      dispatch(createCourseRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(createCourseRequestFailed(json));
      return Promise.reject(json);
    });
};

export const createCourse = (payload) => (dispatch) => dispatch(requestCreateCourse(payload));

const requestGetCourseID = (id) => dispatch => {
  dispatch(courseIdRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiGetCourseID(token, id)
    .then((json) => {
      dispatch(courseIdRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(courseIdRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getCourse = (id) => (dispatch) => dispatch(requestGetCourseID(id));

const requestUpdateCourse = (payload, courseID) => dispatch => {
  dispatch(updateCourseRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiUpdateCourse(token, payload, courseID)
    .then((json) => {
      dispatch(updateCourseRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(updateCourseRequestFailed(json));
      return Promise.reject(json);
    });
};

export const updateCourse = (payload, courseID) => (dispatch) => dispatch(requestUpdateCourse(payload, courseID));
