import {getItemFromStorage} from "./storage";

export const hasToken = () => {
  return !!getToken()
}
export const getToken = () => {
  return getItemFromStorage("ALEXANDRIA_AUTH_TOKEN")
}
export const tokenStillValid = () => {
  if (hasToken()){
    const expireTime = getItemFromStorage("EXPIRE_LOGIN");
    return expireTime > new Date().getTime();
  }
}
export const getRefreshToken = () => {
  return getItemFromStorage("ALEXANDRIA_REFRESH_TOKEN")
}
export const updateToken = (data) => {
  localStorage.setItem("ALEXANDRIA_REFRESH_TOKEN", data['refresh_token']);
  localStorage.setItem("ALEXANDRIA_AUTH_TOKEN", data.token);
  localStorage.setItem("EXPIRE_LOGIN", Date.now().toString());
}
