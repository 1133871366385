import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters} from "./common";
import {getItemFromStorage} from "../../helper/storage";
import {AUTH_TOKEN} from "../../constants/local";

export const apiGetPurchase = (filters) => {
  const token = AUTH_TOKEN 
  return authorizedFetch(urlFilters(`${baseUrl}/shopping/purchases`, filters), {
    method: "GET",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};
