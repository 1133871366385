import {
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_REQUEST_FAILED,
  ADD_ADMIN_REQUEST_SUCCEEDED,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_REQUEST_FAILED,
  DELETE_ADMIN_REQUEST_SUCCEEDED,
  GET_ADMIN_REQUEST,
  GET_ADMIN_REQUEST_FAILED,
  GET_ADMIN_REQUEST_SUCCEEDED,
} from "../constants/admin";

export const getAdminRequest = () => ({
  type: GET_ADMIN_REQUEST,
});

export const getAdminRequestSucceeded = json => ({
  type: GET_ADMIN_REQUEST_SUCCEEDED,
  data: json
});

export const getAdminRequestFailed = json => ({
  type: GET_ADMIN_REQUEST_FAILED,
  error: json
});

export const deleteAdminRequest = () => ({
  type: DELETE_ADMIN_REQUEST,
});

export const deleteAdminRequestSucceeded = json => ({
  type: DELETE_ADMIN_REQUEST_SUCCEEDED,
  data: json
});

export const deleteAdminRequestFailed = json => ({
  type: DELETE_ADMIN_REQUEST_FAILED,
  error: json
});

export const addAdminRequest = () => ({
  type: ADD_ADMIN_REQUEST,
});

export const addAdminRequestSucceeded = json => ({
  type: ADD_ADMIN_REQUEST_SUCCEEDED,
  data: json
});

export const addAdminRequestFailed = json => ({
  type: ADD_ADMIN_REQUEST_FAILED,
  error: json
});
