export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_REQUEST_SUCCEEDED = "GET_PRODUCT_REQUEST_SUCCEEDED";
export const GET_PRODUCT_REQUEST_FAILED = "GET_PRODUCT_REQUEST_FAILED";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_REQUEST_SUCCEEDED = "CREATE_PRODUCT_REQUEST_SUCCEEDED";
export const CREATE_PRODUCT_REQUEST_FAILED = "CREATE_PRODUCT_REQUEST_FAILED";

export const GET_PRODUCT_ID_REQUEST = "GET_PRODUCT_ID_REQUEST";
export const GET_PRODUCT_ID_REQUEST_SUCCEEDED = "GET_PRODUCT_ID_REQUEST_SUCCEEDED";
export const GET_PRODUCT_ID_REQUEST_FAILED = "GET_PRODUCT_ID_REQUEST_FAILED";

export const UPDATE_PRODUCT_ID_REQUEST = "UPDATE_PRODUCT_ID_REQUEST";
export const UPDATE_PRODUCT_ID_REQUEST_SUCCEEDED = "UPDATE_PRODUCT_ID_REQUEST_SUCCEEDED";
export const UPDATE_PRODUCT_ID_REQUEST_FAILED = "UPDATE_PRODUCT_ID_REQUEST_FAILED";
