import * as queryString from "querystring";
import {
  Button, Paper, Dialog, DialogContent,
  Table, TableBody, TableCell, TableHead,
  TablePagination, TableRow,
  Typography, DialogActions, TextareaAutosize, Accordion, AccordionSummary, AccordionDetails, FormControl, InputLabel, Select, MenuItem, CircularProgress
} from '@material-ui/core';
import "./Projects.scss";

import React, { useEffect, useState } from "react";
import DeleteIcon from '@material-ui/icons/PortraitOutlined';
import CheckboxWithDescription from '../../Common/CheckboxWithDescription/CheckboxWithDescription';
import { apiApplicationList, apiSubmitedApplication } from '../../../api/process';
import { ArrowDropDown, CheckCircleOutline } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { urlFilters } from "../../../redux/api/common";
import showNotification from "../../Common/Notification/showNotification";

const Projects = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [description, setDescription] = useState("");

  const locationUse = useLocation();
  const urlParams = queryString.parse(locationUse.search.replace("?", ""))
  const handleOpen = (info) => {
    setInfos(info)
    setOpenModal(true)
  };

  const handleClose = () => {
    setInfos([])
    setOpenModal(false)
    setDescription("")
  };

  const [infos, setInfos] = useState([]);

  const handleChangePage = async (event, newPage) => {
    if (newPage < data?.total_pages) {
      setPage(newPage);
      const result = await apiApplicationList({ page: newPage + 1 });
      setData(result);
    }
  };

  const [status, setStatus] = useState("")

  const buildFilters = () => {
    let filters = {};

    if (status) {
      filters['status'] = status
    }
    return filters;
  }

  const renderList = () => {
    let filters = buildFilters();
    history.push(urlFilters("/match/projetos", filters));
    const fetchData = async (filters) => {
      try {
        setIsFetching(true);
        const result = await apiApplicationList({ ...filters, page: 1 });
        setData(result);
      } catch (error) {
        // handle error
      } finally {
        setIsFetching(false);
      }
    };
    fetchData(filters);
  };

  useEffect(() => {
    renderList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  useEffect(() => {
    setStatus(urlParams['status'] || "");
  }, [])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxHeight: '70vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll'
  };

  useEffect(() => {
    if (!openModal) {
      setSelectedOne(0)
      setSelectedTwo(0)
      setSelectedThree(0)
      setApproved(false)
    }
  }, [openModal])

  const [selectedOne, setSelectedOne] = useState(null);

  const handleAvaliationOne = (id) => {
    setSelectedOne(id);
  };

  const [selectedTwo, setSelectedTwo] = useState(0);

  const handleAvaliationTwo = (id) => {
    setSelectedTwo(id);
  };

  const [selectedThree, setSelectedThree] = useState(0);

  const handleAvaliationThree = (id) => {
    setSelectedThree(id);
  };

  const [totalPoints, setTotalPoints] = useState(0);
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    if (Number(selectedOne) === 1 || Number(selectedOne) === 2 ||
      Number(selectedTwo) === 1 || Number(selectedTwo) === 2) {
      setApproved(false)
    } else {
      if(selectedOne && selectedTwo && selectedThree) {
        if ((Number(selectedOne) + Number(selectedTwo) + Number(selectedThree)) > 7) {
          setApproved(true)
        }
      }
    }
    setTotalPoints(Number(selectedOne) + Number(selectedTwo) + Number(selectedThree))
  }, [selectedOne, selectedTwo, selectedThree])

  const verifyReproved = () => {
    if(selectedOne && selectedTwo && selectedThree) {
      return !approved
    }

    return false;
  }

  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);

  function formatDate(dataString) {
    const data = new Date(dataString);

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    return data.toLocaleDateString('pt-BR', options);
  }

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleApplication = async (status) => {
    try {
      const payload = {
        "email": status.email,
        "status": approved ? "APPROVED" : "NEEDS_IMPROVEMENT",
        "reason": description
      }
      await apiSubmitedApplication(payload)
      showNotification('Avaliação feita com sucesso!', 5000);
      renderList();
    } catch (err) {
      showNotification('Houve um erro na avaliação', 5000);
      renderList();
    }
  }

  return (
    <Paper style={{ marginTop: 100, width: "80%", padding: 20 }} elevation={3}>
      <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", marginBottom: 40, marginRight: 10 }}>
        <h2 style={{ padding: 10 }}>Projetos dos candidatos</h2>
        <div style={{ display: "flex", width: "20%" }}>
          {/* <Search placeholder={"Pesquisar email"} survey value={search} setValue={setSearch} /> */}
          <FormControl fullWidth size="medium" >
            <InputLabel id="demo-simple-select-autowidth-label">Filtro de status</InputLabel>
            <Select
              labelId="demo-simples-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={status}
              label="Filtro de status"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>TODOS</em>
              </MenuItem>
              <MenuItem value="WAITING_APPROVAL">AGUARDANDO CORREÇÃO</MenuItem>
              <MenuItem value="NEEDS_IMPROVEMENT">PRECISA DE MELHORIAS</MenuItem>
              <MenuItem value="APPROVED">APROVADO</MenuItem>
              <MenuItem value="REJECTED">REPROVADO</MenuItem>
              <MenuItem value="WAITING_SUBMISSION">PROJETO NÃO ENTREGUE</MenuItem>
            </Select>
          </FormControl>
        </div>

      </div>
      {!isFetching ? <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="center" >Detalhes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items?.map((row, index) => (
            <TableRow key={index} hover>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.status}</TableCell>
              <TableCell align="center"><DeleteIcon onClick={() => handleOpen(row)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rowsPerPage={data?.page_size}
          rowsPerPageOptions={[1]}
          count={data?.total}
          page={page}
          // onChangePage={handleChangePage}
          onPageChange={handleChangePage}
        />
        <Dialog
          open={openModal}
          onClose={handleClose}
          fullWidth
          maxWidth='md'
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogContent fullWidth sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h3">
              Identificação do aluno
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "space-between" }}>
                <p style={{ textTransform: "capitalize" }}><strong>Nome Completo: </strong> {infos?.name}</p>
                <p> <strong>RG: </strong> {infos?.RG}</p>
                <p> <strong>CPF: </strong> {infos?.CPF}</p>
              </div>
              <div>
                <p><strong>Anexo URL: </strong><a href={infos?.project} target='_blank' rel="noopener noreferrer">{infos?.project}</a></p>
              </div>
            </Typography>
            <hr />
            <Typography id="modal-modal-title" variant="h4" component="h3" style={{ marginTop: 20 }}>
              Avaliação do projeto
              <hr />
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Typography id="modal-modal-title" variant="p" component="p" style={{
                  marginTop: 20,
                  backgroundColor: "#f5f5f5", padding: 10, fontWeight: "bold"
                }}>
                  TÓPICO AVALIATIVO #1 ALINHAMENTO COM O CONTEXTO DA DEMANDA DO PROJETO
                </Typography>
                <div className='title-checks'>
                  <p>ESCALA QUALITATIVA</p>
                  <p>AVALIAÇÃO</p>
                  <p>DESCRIÇÃO DA ANÁLISE</p>
                </div>
                <CheckboxWithDescription
                  id="1"
                  label="(Muito Insatisfatório)"
                  description="A solução proposta não atende às necessidades e demandas do projeto em questão"
                  selected={selectedOne === '1'}
                  onChange={handleAvaliationOne}
                />
                <CheckboxWithDescription
                  id="2"
                  label="(Insatisfatório)"
                  description="A solução proposta possui algumas relevância, mas não atende completamente as expectativas do projeto"
                  selected={selectedOne === '2'}
                  onChange={handleAvaliationOne}
                />
                <CheckboxWithDescription
                  id="3"
                  label="(Satisfatório)"
                  description="A solução proposta é relevante e atende a maioria das expectativas"
                  selected={selectedOne === '3'}
                  onChange={handleAvaliationOne}
                />
                <CheckboxWithDescription
                  id="4"
                  label="(Muito Satisfatório)"
                  description="A solução proposta é altamente relevante ec atende plenamente às demandas deste módulo"
                  selected={selectedOne === '4'}
                  onChange={handleAvaliationOne}
                />
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Typography id="modal-modal-title" variant="p" component="p" style={{
                  marginTop: 20,
                  backgroundColor: "#f5f5f5", padding: 10, fontWeight: "bold"
                }}>
                  TÓPICO AVALIATIVO #2 QUALIDADE DA SOLUÇÃO PROPOSTA
                </Typography>
                <div className='title-checks'>
                  <p>ESCALA QUALITATIVA</p>
                  <p>AVALIAÇÃO</p>
                  <p>DESCRIÇÃO DA ANÁLISE</p>
                </div>
                <CheckboxWithDescription
                  id="1"
                  label="(Muito Insatisfatório)"
                  description="A solução proposta possui muitos problemas e falhas significativas."
                  selected={selectedTwo === '1'}
                  onChange={handleAvaliationTwo}
                />
                <CheckboxWithDescription
                  id="2"
                  label="(Insatisfatório)"
                  description="A solução proposta possui algumas falhas e pode ser melhorada em vários aspectos."
                  selected={selectedTwo === '2'}
                  onChange={handleAvaliationTwo}
                />
                <CheckboxWithDescription
                  id="3"
                  label="(Satisfatório)"
                  description="A solução proposta é de qualidade adequada e atende aos requisitos básicos do mercado."
                  selected={selectedTwo === '3'}
                  onChange={handleAvaliationTwo}
                />
                <CheckboxWithDescription
                  id="4"
                  label="(Muito Satisfatório)"
                  description="A solução proposta é de alta qualidade, demonstrando excelência e inovação em sua abordagem."
                  selected={selectedTwo === '4'}
                  onChange={handleAvaliationTwo}
                />
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Typography id="modal-modal-title" variant="p" component="p" style={{
                  marginTop: 20,
                  backgroundColor: "#f5f5f5", padding: 10, fontWeight: "bold"
                }}>
                  TÓPICO AVALIATIVO #3 VIABILIDADE DE IMPLEMENTAÇÃO EM DINÂMICAS CORPORATIVAS (CAPACIDADE DE USO REAL E IMEDIATO)
                </Typography>
                <div className='title-checks'>
                  <p>ESCALA QUALITATIVA</p>
                  <p>AVALIAÇÃO</p>
                  <p>DESCRIÇÃO DA ANÁLISE</p>
                </div>
                <CheckboxWithDescription
                  id="1"
                  label="(Muito Insatisfatório)"
                  description="A solução proposta não é viável para implementação no mercado."
                  selected={selectedThree === '1'}
                  onChange={handleAvaliationThree}
                />
                <CheckboxWithDescription
                  id="2"
                  label="(Insatisfatório)"
                  description="A solução proposta tem algumas limitações que dificultam sua implementação prática."
                  selected={selectedThree === '2'}
                  onChange={handleAvaliationThree}
                />
                <CheckboxWithDescription
                  id="3"
                  label="(Satisfatório)"
                  description="A solução proposta é viável para uso imediato em dinâmicas empresariais, mas pode exigir ajustes."
                  selected={selectedThree === '3'}
                  onChange={handleAvaliationThree}
                />
                <CheckboxWithDescription
                  id="4"
                  label="(Muito Satisfatório)"
                  description="A solução proposta é facilmente implementável e possui uma abordagem prática e eficiente."
                  selected={selectedThree === '4'}
                  onChange={handleAvaliationThree}
                />
              </Typography>
            </Typography>
            {verifyReproved() &&
              <Typography id="modal-modal-title" variant="h4" component="h3" style={{ marginTop: 20 }}>
                Motivo de reprovação
                <hr />
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <TextareaAutosize
                    minRows='5'
                    value={description}
                    onChange={ev => setDescription(ev.target.value)}
                    placeholder=''
                    defaultValue=''
                    style={{ width: "100%", resize: "none" }} />
                </Typography>
                <Button variant="contained" style={{ background: "red", color: "white" }} color="green" onClick={() => handleApplication(infos)}>Reprovar</Button>
              </Typography>}
            <Typography id="modal-modal-title" variant="h4" component="h3" style={{ marginTop: 20 }}>
              Histórico
              <hr />
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {infos?.history?.length <= 0 && <p>Não possui histórico</p>}
                {infos?.history?.map((item, index) => {
                  return (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ArrowDropDown />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Typography>Status: {item?.status} - Data: {formatDate(item?.createdAt)}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Descrição: {item?.reason}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </Typography>
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}>
            {approved && <Button variant="contained" style={{ background: "green", color: "white" }} color="green" onClick={() => handleApplication(infos)}>Aprovar</Button>}
            <p className={approved ? "green" : "red"} >{approved ? `Avaliação Aprovada ${totalPoints}/12` : `Avaliação reprovada ${totalPoints}/12`}</p>
            <Button onClick={handleClose}>Fechar</Button>
          </DialogActions>
        </Dialog>
      </Table> :
        <div style={{ display: "flex", width: '100%', alignItems: "center", flexDirection: "column" }}>
          <CircularProgress />
          <p>Carregando...</p>
        </div>}
    </Paper>
  );
}

export default Projects;
