import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import {
  createMashieRequest,
  createMashieRequestFailed,
  createMashieRequestSucceeded,
  getMashieDetailsRequest,
  getMashieDetailsRequestFailed,
  getMashieDetailsRequestSucceeded,
  getMashieSubmissionsRequest,
  getMashieSubmissionsRequestFailed,
  getMashieSubmissionsRequestSucceeded,
  listMashiesRequest,
  listMashiesRequestFailed,
  listMashiesRequestSucceeded
} from "../action-creators/mashie";
import { apiCreateMashie, apiGetMashieDetails, apiGetMashieSubmissions, apiListMashies } from "../api/mashie";

const requestListMashies = () => dispatch => {
  dispatch(listMashiesRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiListMashies(token)
    .then(json => {
      dispatch(listMashiesRequestSucceeded(json))
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(listMashiesRequestFailed(json));
      return Promise.reject(json);
    })
}

export const listMashies = () => dispatch => dispatch(requestListMashies());

const requestGetMashieDetails = code => dispatch => {
  dispatch(getMashieDetailsRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiGetMashieDetails(token, code)
    .then(json => {
      dispatch(getMashieDetailsRequestSucceeded(json))
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(getMashieDetailsRequestFailed(json));
      return Promise.reject(json);
    })
}

export const getMashieDetails = code => dispatch => dispatch(requestGetMashieDetails(code));

const requestCreateMashie = (payload) => (dispatch) => {
  dispatch(createMashieRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiCreateMashie(token, payload)
    .then((json) => {
      dispatch(createMashieRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(createMashieRequestFailed(json));
      return Promise.reject(json);
    });
};

export const createMashie = (payload) => (dispatch) => dispatch(requestCreateMashie(payload));

const requestGetSubmissions = (code, filters) => (dispatch) => {
  dispatch(getMashieSubmissionsRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiGetMashieSubmissions(token, code, filters)
    .then((json) => {
      dispatch(getMashieSubmissionsRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(getMashieSubmissionsRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getSubmissions = (code, filters) => (dispatch) => dispatch(requestGetSubmissions(code, filters));
