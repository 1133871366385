import React from 'react';
import "./ContentTagList.scss";

const ContentTagList = ({tags}) => {

  return (
    <div className="ContentTagList">
      {tags && tags.map((item, key) => {
        return <button className="Tag" key={key}>{item?.name}</button>
      })}
    </div>
  );
};

export default ContentTagList;
