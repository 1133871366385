import {
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_REQUEST_FAILED,
  CREATE_PRODUCT_REQUEST_SUCCEEDED,
  GET_PRODUCT_ID_REQUEST,
  GET_PRODUCT_ID_REQUEST_SUCCEEDED,
  GET_PRODUCT_ID_REQUEST_FAILED,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_REQUEST_FAILED,
  GET_PRODUCT_REQUEST_SUCCEEDED,
  UPDATE_PRODUCT_ID_REQUEST,
  UPDATE_PRODUCT_ID_REQUEST_SUCCEEDED,
  UPDATE_PRODUCT_ID_REQUEST_FAILED
} from "../constants/product";

export const product = (
  state = { isFetching: false, data: [], error: {} },
  action
) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_PRODUCT_REQUEST:
    case GET_PRODUCT_ID_REQUEST:
    case UPDATE_PRODUCT_ID_REQUEST:    
    case CREATE_PRODUCT_REQUEST:  
      newState.isFetching = true;
      return newState;

    case GET_PRODUCT_REQUEST_SUCCEEDED:
    case CREATE_PRODUCT_REQUEST_SUCCEEDED:
    case GET_PRODUCT_ID_REQUEST_SUCCEEDED:
    case UPDATE_PRODUCT_ID_REQUEST_SUCCEEDED:      
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_PRODUCT_REQUEST_FAILED:
    case CREATE_PRODUCT_REQUEST_FAILED:
    case GET_PRODUCT_ID_REQUEST_FAILED:
    case UPDATE_PRODUCT_ID_REQUEST_FAILED:      
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
