import {
  GET_PURCHASE_REQUEST,
  GET_PURCHASE_REQUEST_FAILED,
  GET_PURCHASE_REQUEST_SUCCEEDED,
} from "../constants/purchase";

export const purchase = (
  state = { isFetching: false, data: [], error: {} },
  action
) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_PURCHASE_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_PURCHASE_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_PURCHASE_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = [];
      return newState;

    default:
      return newState;
  }
};
