import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";

const CautionDelete = ({name, deleteDescription, deleteApi, successCallback}) => {

  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleClose = () => {
    if(!deleting) {
      setOpen(false);
    }
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleDelete = () => {
    setDeleting(true);
    deleteApi().then(() => {
      setDeleting(false);
      successCallback();
    })
  }

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleOpen}>
        Apagar
      </Button>
      <Dialog
        open={!deleting && open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Apagar ${name} ?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Não
          </Button>
          <Button onClick={handleDelete} color="primary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleting}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Apagando ${name}...`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align={"center"}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CautionDelete;
