import React, { useEffect, useState } from "react";
import { Paper, Table, TableRow, TableCell, TableBody, TableHead, Grid, LinearProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin, getAdmin } from "../../redux/actions/admin";
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialog from "./AlertDialog";

const AdminsTable = () => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [removeItemID, setRemoveItemID] = useState("");
  const getAllAdmin = useSelector(state => state.admins?.data);
  const adminLoading = useSelector(state => state.admins);
  const dispatch = useDispatch();

  const handleClickOpen = (id) => {
    setOpenFeedback(true);
    setRemoveItemID(id);
  };

  useEffect(() => {
    dispatch(getAdmin())
  }, [dispatch]);

  const removeAdmin = () => {
    dispatch(deleteAdmin(removeItemID))
      .then(() => {
        alert('Admin removido com sucesso');
        setOpenFeedback(false);
        dispatch(getAdmin())
      })
      .catch(() => {
        alert('Houve um problema no nosso servidor, tente novamente')
      })
  };

  return (
    <Grid style={{ marginTop: 50 }}>
      {adminLoading.isFetching ? <LinearProgress /> :
        <Paper elevation={3}>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Usuário</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center" colSpan={1}>Liberado por</TableCell>
                <TableCell align="center" colSpan={1}>Desativar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getAllAdmin.items?.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.user.username}</TableCell>
                  <TableCell>{row.user.name}</TableCell>
                  <TableCell>{row.user.email}</TableCell>
                  <TableCell align="center" colSpan={1}>{row.givenBy.name}</TableCell>
                  <TableCell align="center" colSpan={1}><DeleteIcon onClick={() => handleClickOpen(row.id)} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      }
      {openFeedback && <AlertDialog openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} handleYes={removeAdmin} />}
    </Grid>
  )
};

export default AdminsTable;
