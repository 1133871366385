import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid, Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField
} from "@material-ui/core";
import SearchTagComment from "../EditContent/SearchTagComment/SearchTagComment";
import EditorText from "../../Common/EditorText";
import ContentAddOns from "../EditContent/ContentAddOns/ContentAddOns";
import SaveIcon from "@material-ui/icons/Save";
import {getAllTags} from "../../../redux/actions/tags";
import {removeSpecialCharacters} from "../../../helper/removeSpecialCharacters";
import {createContent} from "../../../redux/actions/contents";
import {apiAddTags} from "../../../redux/api/tags";

const CreateContentPage = () => {
  const dispatch = useDispatch();
  const tags = useSelector(state => state.tags?.data);
  const [listTags, setListTags] = useState([]);
  const [nameTag, setNameTag] = useState("");
  const [listTagsID, setListTagsID] = useState([]);
  const [openOptions, setOpenOptions] = useState(false);
  const [valueInput, setValueInput] = useState("");
  const [aspects, setAspects] = useState("");
  const [title, setTitle] = useState("");
  const [platform, setPlatform] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [estimatedDuration, setEstimatedDuration] = useState("");
  const [communicationVehicle, setCommunicationVehicle] = useState("");
  const [isAccessible, setIsAccessible] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [origin, setOrigin] = useState("");
  const [diversityAspects, setDiversityAspects] = useState([]);
  const [textComplete, setTextComplete] = useState("");
  const [contentText, setContentText] = useState({
    content: {text: ""}
  });
  const [contentOriginUrl, setContentOriginUrl] = useState({
    origin_url: "",
    active: false
  });
  const [contentVideoUrl, setContentVideoUrl] = useState({
    video_url: "",
    active: false
  });
  const [contentSpotifyUrl, setContentSpotifyUrl] = useState({
    spotify_url: "",
    active: false
  });

  const resultInput = !valueInput ? tags :
    tags.filter(item => item.name.toLowerCase().includes(valueInput.toLocaleLowerCase()))
      .filter(item => item.name !== valueInput);

  const cards = resultInput.slice(0, 4)

  const removeTag = (name) => {
    setNameTag(name)
    listTags.splice(listTags.indexOf(name), 1)
  }

  const removeTagID = (name) => {
    listTagsID.splice(listTagsID.indexOf(name.toUpperCase()), 1)
  }

  const handleClick = name => {
    setValueInput(name)
  }

  useEffect(() => {
    dispatch(getAllTags())
  }, [dispatch]);

  const renderContents = (active) => {
    if(active === "iframe") {
      return setContentOriginUrl({...contentOriginUrl, active: true})
    }
    if (active === "video") {
      return setContentVideoUrl({...contentVideoUrl, active: true})
    }
    if (active === "spotify") {
      return setContentSpotifyUrl({...contentSpotifyUrl, active: true})
    }
  }

  const handleCreateTag = () => {
    if (valueInput.includes(",")) {
      const noSpecialCharacters = removeSpecialCharacters(valueInput)
      if(noSpecialCharacters.length < 2) {
        setValueInput(removeSpecialCharacters(valueInput))
      } else if(noSpecialCharacters.length > 40) {
        setValueInput(removeSpecialCharacters(valueInput))
      } else {
        setValueInput("")
        listTags.push(noSpecialCharacters)
      }
    }
  }

  const handleCreateContent = () => {
    const payload = {
      title: title,
      platform: platform,
      mediaType: mediaType,
      estimatedDuration: estimatedDuration,
      isAccessible: isAccessible,
      previewImage: previewImage,
      shortDescription: shortDescription,
      content: {
        text: contentText.content.text
      },
      origin: origin,
      tags: [],
      diversityAspects: diversityAspects,
      communicationVehicle: communicationVehicle,
      isDraft: isDraft
    }
    if (contentVideoUrl?.video_url?.length > 1) {
      payload.content.video_url = contentVideoUrl.video_url
    }
    if (contentOriginUrl?.origin_url?.length > 1) {
      payload.content.origin_url = contentOriginUrl.origin_url
    }
    if (contentSpotifyUrl?.spotify_url?.length > 1) {
      payload.content.spotiy_url = contentSpotifyUrl.spotify_url
    }
    let tagsToCreate = listTags.filter(tag => !tags.some(item => tag === item.name));
    let newTagsPromisses = tagsToCreate.map(tag => apiAddTags({
      name: tag
    }));
    Promise.all(newTagsPromisses).then((results) => {
      let newTagIds = results.map(result => result.id);
      payload.tags = listTagsID.concat(newTagIds);
      dispatch(createContent(payload))
        .then(() => {
          alert("Conteúdo criado com sucesso!")
        })
        .catch(() => {
          alert("Erro, tente novamente")
        });
    })
  }

  return (
    <Paper style={{ marginTop: 100, width: "70%", padding: 40 }} elevation={3}>
      <h2>Criar conteúdo</h2>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            label="Título"
            style={{ width: 420, marginBottom: 30 }}
            value={title}
            onChange={(e) => setTitle(e.target.value )}
          />
          <TextField
            label="Breve Descrição"
            variant="filled"
            multiline
            rows={3}
            style={{ width: 420, marginBottom: 30 }}
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
          />
          <div style={{ marginBottom: 30 }}>
            <FormControl
              style={{ width: 200, marginRight: 30 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Plataforma
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Plataforma"
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
              >
                <MenuItem value={"LUNE"}>Lune</MenuItem>
                <MenuItem value={"ALEXANDRIA"}>Alexandria</MenuItem>
                <MenuItem value={"BAIAO_BINARIO"}>Baião binário</MenuItem>
                <MenuItem value={"BLOG"}>Blog</MenuItem>
                <MenuItem value={"CULTURA"}>Cultura</MenuItem>
                <MenuItem value={"JUKEBOX"}>Jukebox</MenuItem>
                <MenuItem value={"E2W"}>e2w</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: 200 }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Tipo de mídia
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Tipo de mídia"
                value={mediaType}
                onChange={(e) => setMediaType(e.target.value)}
              >
                <MenuItem value={"AUDIO"}>Áudio</MenuItem>
                <MenuItem value={"VIDEO"}>Vídeo</MenuItem>
                <MenuItem value={"TEXT"}>Texto</MenuItem>
              </Select>
            </FormControl>
          </div>
          <FormControlLabel
            control={<Switch name="acessivel" color="primary" />}
            label="Acessivel"
            value={isAccessible}
            onChange={(e) => setIsAccessible(e.target.checked)}
          />
          <FormControlLabel
            control={<Switch name="rascunho" color="primary" />}
            label="Rascunho"
            value={isDraft}
            onChange={(e) => setIsDraft(e.target.checked)}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl style={{ width: 200, marginBottom: 30 }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Origem
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Tipo de mídia"
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
            >
              <MenuItem value={"NATIONAL"}>Nacional</MenuItem>
              <MenuItem value={"INTERNATIONAL"}>Internacional</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Link da Imagem"
            style={{ width: 420, marginBottom: 30 }}
            value={previewImage}
            onChange={(e) => setPreviewImage(e.target.value)}
          />
          <TextField
            label="Tempo Estimado"
            style={{ width: 200, marginBottom: 30, marginRight: 20 }}
            value={estimatedDuration}
            onChange={(e) => setEstimatedDuration(Number(e.target.value))}
          />
          <TextField
            label="Veiculo de comunicação"
            style={{ width: 200, marginBottom: 30, marginRight: 20 }}
            value={communicationVehicle}
            onChange={(e) => setCommunicationVehicle(e.target.value)}
          />
          <TextField
            label="Diversidade"
            style={{ width: 200, marginBottom: 30 }}
            value={aspects}
            onChange={(e) => {
              setAspects(e.target.value)
              const aspectsList = e.target.value.split(", ")
              setDiversityAspects(aspectsList)}}
          />
          <Grid>
            <Input
              value={valueInput}
              type="text"
              placeholder="Adicionar tags"
              onChange={(e) => {
                setValueInput(e.target.value)
                handleCreateTag()
              }}/>
            {valueInput && cards.length > 0 ? (
              <div style={{display: !valueInput ? "none" : "flex"}} className="container-search-tags">
                {cards.map((item, key) =>
                  <SearchTagComment item={item} key={key} onClick={() => {
                    listTags.push(item.name)
                    listTagsID.push(item.id)
                    handleClick(item.name)
                    setValueInput("")
                  }}/>
                )}
              </div>
            ) : <p className="insert-tag">Insira uma vírgula depois de cada tag</p>}
            <div className="container-delete-tags">
              {listTags.map((item, key) => {
                return <Button key={key} variant="contained"
                               onClick={() => {
                                 removeTag(item)
                                 removeTagID(item)}}
                >{item}</Button>
              })}
            </div>
          </Grid>
        </Grid>
        <p style={{paddingLeft: "15px", marginBottom: "0"}}>Texto do Conteúdo</p>
        <Grid item xs={12}>
          <EditorText textComplete={textComplete} content={contentText?.content?.text} setContent={setContentText}/>
        </Grid>
        <Grid item xs={12}>
          <p>Adicionais de conteúdo</p>
          <Button label="ok"  onClick={() => setOpenOptions(true)}
                  style={{width: "15%"}} variant="contained" color="primary">
            +
          </Button>
        </Grid>
        <Grid item xs={12}>
          {openOptions &&
          <FormControl style={{width: 200, marginBottom: 30}}>
            <InputLabel id="demo-simple-select-outlined-label">
              Adicionais de conteúdo
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Adicionais de conteúdo"
              onChange={(e) => {
                renderContents(e.target.value)
                setOpenOptions(false)
              }}
            >
              <MenuItem value={"iframe"}>Iframe</MenuItem>
              <MenuItem value={"video"}>Vídeo</MenuItem>
              <MenuItem value={"spotify"}>Spotify</MenuItem>
            </Select>
          </FormControl>}
        </Grid>
        {<ContentAddOns contentVideoUrl={contentVideoUrl} setContentVideoUrl={setContentVideoUrl}
                        contentSpotifyUrl={contentSpotifyUrl} setContentSpotifyUrl={setContentSpotifyUrl}
                        contentOriginUrl={contentOriginUrl} setContentOriginUrl={setContentOriginUrl}/>}
      </Grid>

      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SaveIcon />}
        style={{ marginTop: 40 }}
        onClick={() => handleCreateContent()}
      >
        Salvar
      </Button>
    </Paper>
  )
};

export default CreateContentPage;
