import React from 'react';
import './ContentVideo.scss'
import ReactPlayer from "react-player";

const ContentVideo = ({videoURL}) => {
  return (
    <section className="ContentVideo">
      <ReactPlayer
         url={videoURL} />
    </section>
  );
};

export default ContentVideo;
