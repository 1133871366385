import React from 'react';
import {Button, Grid, TextField} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const ContentAddOns = ({contentVideoUrl, setContentVideoUrl,
                         contentSpotifyUrl, setContentSpotifyUrl,
                         setContentOriginUrl, contentOriginUrl}) => {
  
  return (
    <>
      {contentOriginUrl?.active && <Grid style={{display: "flex", alignItems: "center"}}>
        <TextField
          label="URL do Iframe"
          style={{width: 200, marginBottom: 30, marginLeft: 20}}
          value={contentOriginUrl?.origin_url}
          onChange={(e) => setContentOriginUrl({...contentOriginUrl, origin_url : e.target.value})}
        />
        <Button
          color="primary"
          startIcon={<CancelIcon/>}
          onClick={() => setContentOriginUrl({...contentOriginUrl, active : false})}
        >
        </Button>
      </Grid>}
      {contentVideoUrl?.active &&
      <Grid style={{display: "flex", alignItems: "center"}}>
        <TextField
          label="URL do Video"
          style={{width: 200, marginBottom: 30, marginLeft: 20}}
          value={contentVideoUrl?.video_url}
          onChange={(e) => setContentVideoUrl({...contentVideoUrl, video_url: e.target.value})}
        />
        <Button
          color="primary"
          startIcon={<CancelIcon/>}
          onClick={() => setContentVideoUrl({...contentVideoUrl, active : false})}
        >
        </Button>
      </Grid>}
      {contentSpotifyUrl?.active &&
      <Grid style={{display: "flex", alignItems: "center"}}>
        <TextField
          label="URL do Spotify"
          style={{width: 200, marginBottom: 30, marginLeft: 20}}
          value={contentSpotifyUrl?.spotify_url}
          onChange={(e) => setContentSpotifyUrl({...contentSpotifyUrl, spotify_url: e.target.value})}
        />
        <Button
          color="primary"
          startIcon={<CancelIcon/>}
          onClick={() => setContentSpotifyUrl({...contentSpotifyUrl, active : false})}
        >
        </Button>
      </Grid>}
    </>
  );
};

export default ContentAddOns;
