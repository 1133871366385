import {
  ADD_TAGS_REQUEST,
  ADD_TAGS_REQUEST_FAILED,
  ADD_TAGS_REQUEST_SUCCEEDED,
  GET_TAGS_REQUEST,
  GET_TAGS_REQUEST_FAILED,
  GET_TAGS_REQUEST_SUCCEEDED,
} from "../constants/tags";

export const getTagsRequest = () => ({
  type: GET_TAGS_REQUEST,
});

export const getTagsRequestSucceeded = (json) => ({
  type: GET_TAGS_REQUEST_SUCCEEDED,
  data: json,
});

export const getTagsRequestFailed = (json) => ({
  type: GET_TAGS_REQUEST_FAILED,
  error: json,
});

export const addTagsRequest = () => ({
  type: ADD_TAGS_REQUEST,
});

export const addTagsRequestSucceeded = (json) => ({
  type: ADD_TAGS_REQUEST_SUCCEEDED,
  data: json,
});

export const addTagsRequestFailed = (json) => ({
  type: ADD_TAGS_REQUEST_FAILED,
  error: json,
});
