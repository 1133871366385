import React, { useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table, TableBody, TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

const ByArea = ({ mashieDetailsStore }) => {
  const [questionId, setQuestionId] = useState("");
  const [report, setReport] = useState([]);

  React.useEffect(() => {
    if(questionId !== "") {
      setReport(generateReport(questionId))
    }
  }, [questionId])

  const generateReport = questionId => {
    // protect against loading scenarios
    if (!mashieDetailsStore['answers']) {
      return []
    }

    let report = []
    let grouped = mashieDetailsStore.getGroupedAnswersByQuestionId(questionId)
    for (let groupedKey of Object.keys(grouped)) {
      let ranked = mashieDetailsStore.getSentenceRank(grouped[groupedKey])
      report.push({
        name: groupedKey,
        amount: grouped[groupedKey]?.length,
        most: mashieDetailsStore.getSentenceName(ranked[0].sentenceId),
        least: mashieDetailsStore.getSentenceName(ranked[ranked.length -1].sentenceId)
      })
    }

    return report
  }

  return (
    <Paper style={{ margin: "40px 100px 40px 0", padding: 20, width: "80%" }} elevation={3}>
      <Grid container>
        <Grid style={{ display: "flex", padding: 20, width: "100%", justifyContent: "space-between" }}>

          <Typography style={{ margin: "10px 20px" }} variant={"h5"}>
            Por área
          </Typography>
          <div style={{ marginBottom: 30 }}>
            <FormControl
              style={{ width: 250, marginRight: 30 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Agrupar por
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Distribuição de Sentenças"
                onChange={(e) => setQuestionId(e.target.value)}
              >
                {mashieDetailsStore?.settings?.questions?.map((item, index) => {
                  return <MenuItem key={index} value={item.id}>
                    {item.prompt}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          {report.length > 0 && <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>{mashieDetailsStore.getQuestionPrompt(questionId)}</TableCell>
                <TableCell>#</TableCell>
                <TableCell>Primeiro</TableCell>
                <TableCell>Último</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {report?.map((row, index) => {
                return <TableRow key={index} hover>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.most}</TableCell>
                  <TableCell>{row.least}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ByArea;
