import { AUTH_TOKEN, REFRESH_TOKEN, USER_ID } from "../../constants/local";
import {saveItemInStorage, saveLoginStorage} from "../../helper/storage";
import {
  loginRequest,
  loginRequestSucceeded,
  loginRequestFailed,
  loginWithGoogleSucceeded, loginWithGoogle, googleLoginSucceeded
} from "../action-creators/login";
import {apiGoogleLogin, apiLogin} from "../api/login";

const requestLogin = (payload, keepLogged) => dispatch => {
  dispatch(loginRequest());
  return apiLogin(payload)
    .then( async (json) => {
      await dispatch(loginRequestSucceeded(json));
      saveItemInStorage(AUTH_TOKEN, json.token, keepLogged);
      saveItemInStorage(REFRESH_TOKEN, json.refresh_token, keepLogged);
      saveItemInStorage(USER_ID, json?.user?.id, keepLogged);
      return Promise.resolve();
    })
    .catch(json => {
      dispatch(loginRequestFailed(json));
      return Promise.reject(json);
    })
};

export const login = (payload, keepLogged) => dispatch => dispatch(requestLogin(payload, keepLogged));

const createLoginWithGoogleRequest = payload => dispatch => {
  dispatch(loginWithGoogle());

  return apiGoogleLogin(payload)
    .then(json => {
      dispatch(loginWithGoogleSucceeded(json));
      saveLoginStorage(json)
      return Promise.resolve();
    })
    .catch(response => response);
};

export const googleLogin = payload => dispatch => dispatch(createLoginWithGoogleRequest(payload));

export const registerGoogleLogin = googleData => dispatch => {
  dispatch(googleLoginSucceeded(googleData));
  const payload = {tokenId: googleData.tokenId};

  return dispatch(googleLogin(payload));
};

export const registerGoogleLoginFailed = message => dispatch => Promise.reject(message);
