import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters} from "./common";
import {AUTH_TOKEN} from "../../constants/local";

export const apiGetProducts = (filters) => {
  const token = AUTH_TOKEN 
  return authorizedFetch(urlFilters(`${baseUrl}/shopping/products`, filters), {
    method: "GET",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiDeleteProduct = (token, code) => {
  return authorizedFetch(`${baseUrl}/shopping/product/${code}`, {
    method: "DELETE",
    headers: buildHeaders(token),
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiCreateProduct = (token, payload) => {
  return authorizedFetch(`${baseUrl}/shopping/product`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    }).catch(json => {
      return Promise.reject(json);
    })
};

export const apiGetProductID = (token, id) => {
  return authorizedFetch(`${baseUrl}/shopping/product/${id}`, {
    method: "GET",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};

export const apiUpdateProduct = (token, payload, id) => {
  return authorizedFetch(`${baseUrl}/shopping/product/${id}`, {
    method: "PATCH",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};
