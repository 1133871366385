import {
  CREATE_CONTENT_REQUEST,
  CREATE_CONTENT_REQUEST_FAILED,
  CREATE_CONTENT_REQUEST_SUCCEEDED,
  DELETE_CONTENTS_REQUEST,
  DELETE_CONTENTS_REQUEST_FAILED,
  DELETE_CONTENTS_REQUEST_SUCCEEDED,
  GET_CONTENTS_REQUEST,
  GET_CONTENTS_REQUEST_FAILED,
  GET_CONTENTS_REQUEST_SUCCEEDED,
  UPDATE_CONTENTS_REQUEST,
  UPDATE_CONTENTS_REQUEST_FAILED,
  UPDATE_CONTENTS_REQUEST_SUCCEEDED,
} from "../constants/contents";

export const contentRequest = () => ({
  type: GET_CONTENTS_REQUEST,
});

export const contentRequestSucceeded = (json) => ({
  type: GET_CONTENTS_REQUEST_SUCCEEDED,
  data: json,
});

export const contentRequestFailed = (json) => ({
  type: GET_CONTENTS_REQUEST_FAILED,
  error: json,
});

export const deleteContentRequest = () => ({
  type: DELETE_CONTENTS_REQUEST,
});

export const deleteContentRequestSucceeded = json => ({
  type: DELETE_CONTENTS_REQUEST_SUCCEEDED,
  data: json
});

export const deleteContentRequestFailed = json => ({
  type: DELETE_CONTENTS_REQUEST_FAILED,
  error: json
});

export const updateContentRequest = () => ({
  type: UPDATE_CONTENTS_REQUEST,
});

export const updateContentRequestSucceeded = (json) => ({
  type: UPDATE_CONTENTS_REQUEST_SUCCEEDED,
  data: json
});

export const updateContentRequestFailed = (json) => ({
  type: UPDATE_CONTENTS_REQUEST_FAILED,
  error: json
});

export const createContentRequest = () => ({
  type: CREATE_CONTENT_REQUEST,
});

export const createContentRequestSucceeded = (json) => ({
  type: CREATE_CONTENT_REQUEST_SUCCEEDED,
  data: json
});

export const createContentRequestFailed = (json) => ({
  type: CREATE_CONTENT_REQUEST_FAILED,
  error: json
});
