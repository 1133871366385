import { Button, Grid, Paper, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postEmail } from '../../../../redux/actions/email';
import EmailCSV from '../EmailCSV/EmailCSV';
import FullModalEmail from '../FullModalEmail/FullModalEmail';

const SendEmail = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [idTemplate, setIdTemplate] = useState({ idTemplate: "", nameTemplate: "" });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailCSV, setEmailCSV] = useState([]);
  const [metadataCSV, setMetadataCSV] = useState([]);

  const openModalView = () => {
    setOpenModal(true);
  };

  const sendEmail = () => {
    if (email === "" || name === "") {
      alert("Preencha os dados de email e nome");
    } else if (Array.isArray(metadataCSV)) {
      alert("Coloque o csv de emails");
    } else {
      const payload = {
        file: metadataCSV,
        sender: name + `<${email}>`,
        templateId: idTemplate.idTemplate
      }
      dispatch(postEmail(payload))
        .then((json) => {
          alert("Emails enviados com sucesso!")
        })
        .catch((json) => {
          alert("Erro, tente novamente")
        });
    }
  }

  return (
    <Paper style={{ marginTop: 100, width: "80%", padding: 40 }} elevation={3}>
      <h1>Enviar email</h1>
      <Grid>
        <Button variant="contained" color="primary" size="large" onClick={openModalView}>
          Escolher template do sendgrid
        </Button>
      </Grid>

      {idTemplate.nameTemplate &&
        <>
          <div style={{ margin: "20px 0" }}>
            <strong>Selecionado</strong>
            <p>{idTemplate.nameTemplate}</p>
          </div>
          <Grid container style={{ marginTop: 50 }} >
            <TextField
              required
              label="Nome do remetente"
              style={{ width: 420, marginBottom: 30 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              required
              label="Email do remetente"
              style={{ width: 420, marginBottom: 30, marginLeft: 34 }}
              value={email}
              type='email'
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <EmailCSV setEmailCSV={setEmailCSV} emailCSV={emailCSV} setMetadataCSV={setMetadataCSV} />


            <Button style={{ marginTop: 30 }} variant="contained" color="primary" size="large" onClick={sendEmail}>
              Disparar emails
            </Button>
          </div>
        </>}
      {openModal && <FullModalEmail open={openModal} setOpen={setOpenModal} item={setIdTemplate} />}
    </Paper>
  )
}

export default SendEmail
