import {
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_REQUEST_FAILED,
  CREATE_PRODUCT_REQUEST_SUCCEEDED,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_REQUEST_FAILED,
  GET_PRODUCT_REQUEST_SUCCEEDED,
  GET_PRODUCT_ID_REQUEST,
  GET_PRODUCT_ID_REQUEST_FAILED,
  GET_PRODUCT_ID_REQUEST_SUCCEEDED,
  UPDATE_PRODUCT_ID_REQUEST,
  UPDATE_PRODUCT_ID_REQUEST_FAILED,
  UPDATE_PRODUCT_ID_REQUEST_SUCCEEDED,
} from "../constants/product";

export const getProductRequest = () => ({
  type: GET_PRODUCT_REQUEST,
});

export const getProductRequestSucceeded = (json) => ({
  type: GET_PRODUCT_REQUEST_SUCCEEDED,
  data: json,
});

export const getProductRequestFailed = (json) => ({
  type: GET_PRODUCT_REQUEST_FAILED,
  error: json,
});

export const createProductRequest = () => ({
  type: CREATE_PRODUCT_REQUEST,
});

export const createProductRequestSucceeded = json => ({
  type: CREATE_PRODUCT_REQUEST_SUCCEEDED,
  data: json
});

export const createProductRequestFailed = json => ({
  type: CREATE_PRODUCT_REQUEST_FAILED,
  error: json
});

export const getProductIdRequest = () => ({
  type: GET_PRODUCT_ID_REQUEST,
});

export const getProductIdRequestSucceeded = (json) => ({
  type: GET_PRODUCT_ID_REQUEST_SUCCEEDED,
  data: json,
});

export const getProductIdRequestFailed = (json) => ({
  type: GET_PRODUCT_ID_REQUEST_FAILED,
  error: json,
});

export const updateProductIdRequest = () => ({
  type: UPDATE_PRODUCT_ID_REQUEST,
});

export const updateProductIdRequestSucceeded = (json) => ({
  type: UPDATE_PRODUCT_ID_REQUEST_SUCCEEDED,
  data: json,
});

export const updateProductIdRequestFailed = (json) => ({
  type: UPDATE_PRODUCT_ID_REQUEST_FAILED,
  error: json,
});
