import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import { createProductRequest, createProductRequestFailed, createProductRequestSucceeded, getProductIdRequest, getProductIdRequestFailed, getProductRequest, getProductRequestFailed, getProductRequestSucceeded, updateProductIdRequest, updateProductIdRequestFailed, updateProductIdRequestSucceeded } from "../action-creators/product";
import { apiGetProducts, apiCreateProduct, apiGetProductID, apiUpdateProduct } from "../api/product";

const requestGetProduct = (filters) => (dispatch) => {
  dispatch(getProductRequest());
  return apiGetProducts(filters)
    .then((json) => {
      dispatch(getProductRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(getProductRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getAllProducts = (filters) => (dispatch) => dispatch(requestGetProduct(filters));

const requestCreateProduct = (payload) => (dispatch) => {
  dispatch(createProductRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiCreateProduct(token, payload)
    .then((json) => {
      dispatch(createProductRequestSucceeded(json));
      return Promise.resolve();
    })
    .catch((json) => {
      dispatch(createProductRequestFailed(json));
      return Promise.reject(json);
    });
};

export const createProduct = (payload) => (dispatch) => dispatch(requestCreateProduct(payload));

const requestGetProductID = (id) => dispatch => {
  dispatch(getProductIdRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiGetProductID(token, id)
    .then((json) => {
      dispatch(getProductRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(getProductIdRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getProductId = (id) => (dispatch) => dispatch(requestGetProductID(id));


const requestUpdateProduct = (payload, productID) => dispatch => {
  dispatch(updateProductIdRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiUpdateProduct(token, payload, productID)
    .then((json) => {
      dispatch(updateProductIdRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(updateProductIdRequestFailed(json));
      return Promise.reject(json);
    });
};

export const updateProduct = (payload, productID) => (dispatch) => dispatch(requestUpdateProduct(payload, productID));
