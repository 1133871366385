import React, { useState } from "react";
import Logo from "../../../assets/img/logo-mastertech-mini.png";
import { Card, Button } from "@material-ui/core";
import "./Password.scss";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { useHistory } from "react-router-dom";

const RecoveryCode = () => {
  const [codeOne, setCodeOne] = useState(new Array(6).fill(""));
  const history = useHistory();

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setCodeOne([...codeOne.map((d, idx) => idx === index ? element.value : d)]);

    if (element.nextSibling) {
      element.nextSibling.focus()
    }
  };

  const urlCode = codeOne.join("");

  return (
    <section className="Password">
      <img src={Logo} alt="logo Mastertech" className="logo" />
      <Card className="container">
        <span className="rounded">
          <LockIcon />
        </span>
        <p className="subtitle">Digite o código</p>
        <p className="innerText">
          Insira o código de 6 dígitos que enviamos para seu email.
        </p>
        <div className="code-container">
          {codeOne.map((data, index) => {
            return (
              <input onChange={e => handleChange(e.target, index)}
                name="codeOne"
                className="otp-field"
                type="text"
                value={data}
                key={index}
                maxLength="1"
                onFocus={e => e.target.select()}
              />
            )
          })}
        </div>
        <Button variant="contained" className="btn" onClick={() => {
          urlCode.length >= 6 ? history.push(`/redefinir-senha/codigo/${urlCode}`)
            : alert("Preencha todos os campos");
        }}>
          CONFIRMAR
        </Button>
      </Card>
    </section>
  );
};

export default RecoveryCode;
