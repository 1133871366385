import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddQuestions from "../../mashie/mashieDetails/CreateMashie/AddQuestions/AddQuestions";
import { useDispatch } from "react-redux";
import { getCourse, getCourses } from '../../../redux/actions/courses';
import { createProduct, getProductId, updateProduct } from '../../../redux/actions/product';
import { useHistory, useParams } from 'react-router-dom';
import { formatPrice } from '../../../utils/format';

const EditProduct = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([""]);
  const [title, setTitle] = useState("");
  const [supply, setSupply] = useState("");
  const [value, setValue] = useState("");
  const [stopSalesOn, setStopSalesOn] = useState("");
  const [platform, setPlatform] = useState("");
  const [starterQuestionList, setStarterQuestionList] = useState([]);
  const [coursesInfo, setCoursesInfo] = useState([]);
  const [nameCourse, setNameCourse] = useState("");

  const addQuestion = () => {
    const questionList = [...questions, ""];
    setQuestions(questionList);
  };

  useEffect(() => {
    dispatch(getCourses({ type: "people" })).then((json) => {
      setCoursesInfo(json)
    })
  }, []);

  useEffect(() => {
    dispatch(getProductId(id)).then((json) => {
      console.log(json)
      setTitle(json.name || "")
      setSupply(json.supply || "")
      setValue(json.value || "")
      let date = new Date(json.stopSalesOn).toISOString();
      let formatDate = date.substring(0, date.indexOf("T"));
      setStopSalesOn(formatDate || "")
      setPlatform(json.external_reference.replace('ms-course/course/', "") || "")
      setQuestions(json.questions || []);
      setStarterQuestionList(json.questions || []);
    })
  }, [dispatch, id]);
  

  const handleUpdateProduct = () => {
    for (var i = 0; i < starterQuestionList.length; i++) {
      delete starterQuestionList[i].id;
      delete starterQuestionList[i].validationRegex;
      delete starterQuestionList[i].values;
    }
    let isoDateString = new Date(stopSalesOn)?.toISOString();
    let formatDate = isoDateString.substring(0, isoDateString.indexOf(".000Z"));
    const payload = {
      supply,
      externalReference: "ms-course/course/" + platform,
      value,
      stopSalesOn: formatDate,
      name: title,
      questions: starterQuestionList
    }
    console.log(payload)
    dispatch(updateProduct(payload, id))
      .then(() => {
        alert("Produto editado com sucesso!")
        history.push("/ecommerce/produtos");
      })
      .catch(() => {
        alert("Erro, tente novamente")
      });
  }
  console.log(value)
  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: "40px" }}>
      <Paper style={{ marginTop: 100, padding: 40 }}>
        <Typography variant={"h4"}>
          Informações iniciais
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <TextField
              label="Título"
              style={{ width: 420, marginBottom: 30 }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Vagas"
              style={{ width: 420, marginBottom: 30 }}
              value={supply}
              onChange={(e) => setSupply(parseInt(e.target.value))}
            />
            <div style={{ marginBottom: 30 }}>
              <FormControl
                style={{ width: 420, marginRight: 30 }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Curso referente
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Distribuição de Sentenças"
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                >
                  {coursesInfo && coursesInfo?.items?.map((course) =>
                    <MenuItem value={course?.id}>{course.name}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <div style={{ marginTop: "20px", width: "75%" }}>
                <strong>Curso de referência:</strong>

                <p>Um curso de referente, signifca que esse produto é referente a determinado curso.
                  Determinando assim, valor e vagas do curso pelo produto</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <TextField
              label={value ? "Valor do produto - " + formatPrice(value/100) : "Valor do produto"}
              style={{ width: 420, marginBottom: 30 }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <TextField
              InputLabelProps={{ shrink: true, required: true }}
              type="date"
              label="Data - Fechamento de vendas"
              style={{ width: 420, marginBottom: 30 }}
              value={stopSalesOn}
              onChange={(e) => setStopSalesOn(e.target.value)}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{ marginTop: 30, padding: 40 }}>
        <Grid container justify="space-between">
          <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between", width: "100%" }}>
            <Typography variant="h4">
              Perguntas iniciais
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={addQuestion}
              startIcon={<AddCircleIcon />}
              style={{ marginBottom: "40px" }}
            >
              nova pergunta
            </Button>
          </div>
        </Grid>
        <Grid container direction="column"
          alignItems="flex-start">
          <Grid container xs={12}>
            <AddQuestions
              starterQuestionList={starterQuestionList}
              setStarterQuestionList={setStarterQuestionList}
              setQuestions={setQuestions}
              questions={questions} />
          </Grid>
        </Grid>
      </Paper>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "30px" }}
        size="small"
        onClick={() => handleUpdateProduct()}
        startIcon={<AddCircleIcon />}
      >
        Atualizar
      </Button>
    </div>
  );
};

export default EditProduct;
