import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Slide, Typography, IconButton, AppBar, Toolbar, Dialog, LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getContentItem } from '../../redux/actions/contentItem';
import UserContent from "./ContentScreen/UserContent/UserContent";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  subtitle: {
    paddingBottom: 20,
    paddingTop: 20
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullModalContent = ({ open, setOpen, item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const itemContent = useSelector((state) => state.content?.data);
  const contentLoading = useSelector((state) => state.content);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getContentItem(item))
  }, [dispatch, item]);

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {itemContent.title}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              editar
            </Button>
          </Toolbar>
        </AppBar>
        {contentLoading.isFetching ? <LinearProgress color="secondary"/> :
          <div style={{display: "flex", justifyContent: "center"}}>
            <UserContent content={itemContent}/>
          </div>
        }
      </Dialog>
    </div>
  );
}

export default FullModalContent;
