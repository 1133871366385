import React from 'react';
import { Paper, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const Search = ({ placeholder, survey, value, setValue }) => {

  return (
    <Paper component="form" style={{ alignItems: "center", display: 'flex', justifyContent: "space-around", width: 350, }}>
      <InputBase placeholder={placeholder} 
      value={value}
      onChange={(e) => setValue(e.target.value)} />
      <IconButton aria-label="search" onClick={survey}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default Search;
