import React, { useState } from "react";
import Logo from "../../../assets/img/logo-mastertech-mini.png";
import { Card, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, CircularProgress } from "@material-ui/core";
import "./Password.scss";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch, useSelector } from "react-redux";
import { userSetNewPassword } from "../../../redux/actions/user";
import { useHistory, useParams } from "react-router-dom";

const NewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(state => state.user?.data);
  const {code} = useParams();
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

  const handleNewPassword = () => {
    const payload = {
      password: newPassword
    }
    dispatch(userSetNewPassword(userData?.token, code, payload))
    .then(() => {
      setShowFeedback(true);
      setLoading(true);
      setFeedbackMessage("Senha atualizada com sucesso!");
      setTimeout(() => {
        setLoading(false);
        setFeedbackMessage(false);
        history.push("/login");
      }, 3000);
    })
    .catch(() => {
      setShowFeedback(true);
      setFeedbackMessage("Houve um problema no nosso servidor, tente novamente");
      setTimeout(() => {
        setFeedbackMessage(false);
        setNewPassword("");
      }, 3000);
    })
  }

  return (
    <section className="Password">
      <img src={Logo} alt="logo Mastertech" className="logo" />
      <Card className="container">
        <span className="rounded">
          <LockIcon />
        </span>
        <p className="subtitle">Digite sua nova senha</p>
        <p className="innerText">
          Insira uma nova senha no campo abaixo. Deve conter 6 caracteres dentre eles letras e números.
        </p>
        <FormControl variant="outlined" style={{width: '100%', margin: 10}}>
          <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            label="Senha"
            variant="outlined"
            value={newPassword}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setNewPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <p className={showFeedback ? "errorMessageActive" : "errorMessage"}>{feedbackMessage}</p>
        {loading ? <CircularProgress /> : 
        <Button variant="contained" className="btn" onClick={handleNewPassword}>
          ENVIAR
        </Button>
        }
      </Card>
    </section>
  );
};

export default NewPassword;
