import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/logo-mastertech-mini.png";
import { TextField, Card, Button, IconButton, InputAdornment, OutlinedInput, InputLabel, FormControl } from "@material-ui/core";
import "./Login.scss";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-logo-2019.svg";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/login";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import GoogleSignIn from "./GoogleSignIn/GoogleSignIn";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const loginData = useSelector(state => state.login);
  
  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

  useEffect(() => {
    if (Object.keys(loginData?.data).length > 0) {
      if (loginData?.data?.user?.isAdmin) {
        history.push("/home");
      } else {
        alert("Você não tem acesso de administrador");
        window.location.href = "http://alexandria.homolog.mastertech.com.br/";
      }
    }
  }, [loginData, history]);

  const handleSubmit = () => {
    const payload = {
      email,
      password,
    };
    dispatch(login(payload, true)).catch((json) => {
      if (json.status === 401) {
        history.push("/login");
      }
      setError(true);
      setEmail("");
      setPassword("");
      setTimeout(() => {
        setError(false);
      }, 5000);
    });
  };

  return (
    <section className="Login">
      <img src={Logo} alt="logo Mastertech" className="logo" />
      <Card className="container">
        <div className="social-buttons">
          <Button variant="outlined" className="btn" startIcon={<FacebookIcon />}>
            Entrar com Facebook
          </Button>
          <GoogleSignIn buttonText="Continuar com o Google" />
        </div>
        <p className="innerText">ou acesse pelo seu email e senha:</p>
        <p className={error ? "errorMessageActive" : "errorMessage"}>E-mail e/ou senha incorretos</p>
        <TextField
          id="outlined-basic"
          label="Login"
          variant="outlined"
          className="inputField"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            label="Senha"
            variant="outlined"
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Link to="/esqueceu-senha" className="forgotPassword">
          Esqueceu sua senha?
        </Link>
        <Button variant="contained" className="btn" onClick={handleSubmit}>
          Entrar
        </Button>
        <div className="signup">
          <p>Não tem cadastro ainda? </p>
          <Link to="/cadastro" className="cta">
            Acesse aqui
          </Link>
        </div>
      </Card>
    </section>
  );
};

export default Login;
