import {authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow, urlFilters} from "./common";

export const apiGetContents = (token, filters) => {
  return authorizedFetch(urlFilters(`${baseUrl}/content/contents`, filters), {
    method: "GET",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};

export const apiDeleteContents = (token, id) => {
  return authorizedFetch(`${baseUrl}/content/content/${id}`, {
    method: "DELETE",
    headers: buildHeaders(token)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};

export const apiUpdateContents = (token, id, payload) => {
  return authorizedFetch(`${baseUrl}/content/content/${id}`, {
    method: "PATCH",
    headers: buildHeaders(token),
    body:JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};

export const apiCreateContent = (token, payload) => {
  return authorizedFetch(`${baseUrl}/content/content`, {
    method: "POST",
    headers: buildHeaders(token),
    body:JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json)
    })
    .catch((json) => {
      return Promise.reject(json)
    });
};
