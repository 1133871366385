import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { getSubmissions } from "../../../../../redux/actions/mashie";
import AnswerTableRow from "../AnswerTableRow/AnswerTableRow";

const AnswerTable = ({ mashie, mashieSubmission, code }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const dispatch = useDispatch()

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    dispatch(getSubmissions(code, { page: newPage + 1 }));
  };

  const emptyRows = 0

  return (
    <Paper style={{ marginTop: 20, marginRight: 100, padding: 20, width: '100%' }} elevation={3}>
      <Typography variant={"h5"}>
        Lista de respostas recebidas
      </Typography>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell />
            {/* {mashieSubmission?.items?.questionAnswers[0]?.answer
              && */}
            <TableCell>Primeira Pergunta</TableCell>
            <TableCell>Id</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Inicio</TableCell>
            <TableCell align="center">Fim</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mashieSubmission.items.map(answer => {
            return <AnswerTableRow mashie={mashie} mashieSubmission={mashieSubmission} answer={answer} />
          })}
        </TableBody>
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * (rowsPerPage - mashieSubmission.items?.length) }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
        <TablePagination
          rowsPerPageOptions={[12]}
          rowsPerPage={rowsPerPage}
          count={mashieSubmission?.totalItems}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
        />
      </Table>
    </Paper>
  );
};

export default AnswerTable;
