import { AppBar, Dialog, IconButton, LinearProgress, Slide, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplate } from '../../../../redux/actions/email';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  subtitle: {
    paddingBottom: 20,
    paddingTop: 20
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullModalEmail = ({ open, setOpen, item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const itemContent = useSelector((state) => state.email?.data);
  const contentLoading = useSelector((state) => state.email);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getTemplate());
  }, [dispatch]);
  console.log()

  const saveItem = (save) => {
    item({ idTemplate: save.id, nameTemplate: save.name })
    setOpen(false);
  }

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {itemContent.title}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              fechar
            </Button>
          </Toolbar>
        </AppBar>
        {contentLoading.isFetching ? <LinearProgress color="secondary" /> :
          <div style={{ display: "flex", justifyContent: "center", margin: "20px 0", flexWrap: "wrap", gap: "20px" }}>
            {itemContent?.result?.map((result) => {
              {
                return (<>
                  {result.versions[0]?.thumbnail_url && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", border: '1px solid grey' }}>
                    <img src={`https:${result.versions[0]?.thumbnail_url}`} />~
                    <strong>{result.name}</strong>
                    <Button style={{ margin: "10px 0", width: "50%" }} variant="contained" color="primary" size="large" onClick={() => saveItem(result)}>Selecionar</Button>
                  </div>}
                </>)
              }
            })}
          </div>
        }
      </Dialog >
    </div >
  );
}

export default FullModalEmail;
