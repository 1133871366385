import { AUTH_TOKEN } from "../../constants/local";
import { getItemFromStorage } from "../../helper/storage";
import { getContentItemRequest, getContentItemRequestFailed, getContentItemRequestSucceeded } from "../action-creators/contentItem";
import { apiGetContentItem } from "../api/contentItem";

const requestGetContentItem = (id) => (dispatch) => {
  dispatch(getContentItemRequest());
  const token = getItemFromStorage(AUTH_TOKEN);
  return apiGetContentItem(token, id)
    .then((json) => {
      dispatch(getContentItemRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(getContentItemRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getContentItem = (id) => (dispatch) => dispatch(requestGetContentItem(id));
