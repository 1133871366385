import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import RecoveryCode from './Entry/Password/RecoveryCode';
import NewPassword from './Entry/Password/NewPassword';
import ForgotPassword from './Entry/Password/ForgotPassword';
import Login from './Entry/Login';
import SignUp from './Entry/SignUp';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Menu from './Common/Menu';

const Routes = () => (
  <BrowserRouter>
    <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/cadastro" exact component={SignUp} />
        <Route path="/esqueceu-senha" exact component={ForgotPassword} />
        <Route path="/redefinir-senha/codigo" exact component={RecoveryCode} />
        <Route path="/redefinir-senha/codigo/:code" exact component={NewPassword} />
        <PrivateRoute path="/" component={Menu} />
    </Switch>
  </BrowserRouter>
)

export default Routes;
