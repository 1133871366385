import React, { useEffect, useState } from 'react';
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table, TableBody, TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

const AverageReport = ({ mashieDetailsStore }) => {
  const [reportResponse, setReportResponse] = useState([]);
  const [sample, setSample] = useState();
  let reportList = []

  const getReportResponse = (question) => {
    let response = {};
    for (let answer of mashieDetailsStore?.answers) {
      for (let questionAnswer of answer.questionAnswers) {
        if (questionAnswer.questionId === question) {
          if (!response[questionAnswer.answer]) {
            response[questionAnswer.answer] = 0
          }
          response[questionAnswer.answer] = response[questionAnswer.answer] + 1
        }
      }
    }
    return setReportResponse(response);
  }

  useEffect(() => {
    mashieDetailsStore?.answers && getReportResponse(mashieDetailsStore?.settings?.questions[0]?.id)
  }, [mashieDetailsStore]);

  const renderReport = () => {
    for (let item in reportResponse) {
      reportList.push({ average: reportResponse[item], answer: item })
    }
  }

  renderReport()

  return (
    <Paper style={{ margin: "100px 100px 40px 0", padding: 20, width: "min-content" }} elevation={3}>
      <Grid container>
        <Grid style={{ display: "flex", padding: 20, width: "500px", justifyContent: "space-between" }}>

          <Typography style={{ margin: "10px 20px" }} variant={"h5"}>
            A amostra
          </Typography>
          <div style={{ marginBottom: 30 }}>
            <FormControl
              style={{ width: 250, marginRight: 30 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Agrupar por
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Distribuição de Sentenças"
                onChange={(e) => getReportResponse(e.target.value)}
              >
                {mashieDetailsStore?.settings?.questions?.map((item, index) => {
                  return <MenuItem key={index} value={item.id}
                    onClick={() => setSample(item.prompt)}>
                    {item.prompt}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item style={{width: "100%"}}>
          {reportList.length >= 1 && <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>{sample ? sample : mashieDetailsStore?.settings?.questions[0]?.prompt}</TableCell>
                <TableCell>Contagem</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportList?.map((sample, key) => {
                return <TableRow key={key} hover>
                  <TableCell>{sample.answer}</TableCell>
                  <TableCell>{sample.average}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AverageReport;
