import React, { useEffect, useState } from 'react';
import { Paper, Chip, LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { addTags, getAllTags } from '../../redux/actions/tags';
import Input from '../Common/Input';
import Search from '../Common/Search';
import { useCallback } from 'react';

const TagsPage = () => {
  const getTags = useSelector(state => state.tags);
  const dispatch = useDispatch();
  const [tagName, setTagName] = useState("");
  const [searchedTag, setSearchedTag] = useState("");
  const [tagFilter, setTagFilter] = useState(getTags.data || []);

  const handleSubmitTags = () => {
    const payload = {
      name: tagName,
    };
    dispatch(addTags(payload))
      .then(() => {
        alert('Tag cadastrada');
        dispatch(getAllTags());
        setTagName("");
      })
      .catch(() => {
        alert("Houve um problema no nosso servidor");
      })
  };

  useEffect(() => {
    dispatch(getAllTags())
  }, [dispatch]);

  const searchTags = useCallback(() => {
    let search = getTags?.data.filter((tag) => tag.id.includes(searchedTag.toUpperCase()));
    setTagFilter(search);
  }, [getTags?.data, searchedTag])

  useEffect(() => {
    searchTags();
  }, [searchedTag, searchTags]);

  useEffect(() => {
    setTagFilter(getTags.data);
  }, [getTags.data]);

  return (
    <div style={{ marginTop: 100, width: '80%' }}>
      {getTags.isFetching ? <LinearProgress /> :
        <Paper elevation={3} style={{ padding: 20 }}>
          <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", marginBottom: 20, padding: 10 }}>
            <h2>Tags do sistema</h2>
            <Search placeholder={"Pesquisar tags"} survey={searchTags} value={searchedTag} setValue={setSearchedTag} />
          </div>
          {tagFilter.map(tag => {
            return <Chip label={tag.name} onDelete={() => { alert("Ainda não é possível deletar as tags") }} color="primary" variant="outlined" style={{ margin: 6 }} />
          })}
        </Paper>
      }
      <Input value={tagName} setValue={setTagName} handleSend={handleSubmitTags} text={"uma nova tag"} input={"tag"} />
    </div>
  )
};

export default TagsPage;
