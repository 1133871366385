export const secondsToShortFormat = (seconds) => {
    let result = ""
    if(seconds > 60*60) {
        result += parseInt(seconds / (60*60)) + "h"
        seconds = seconds % (60*60)
    }
    if(seconds > 60) {
        result += parseInt(seconds / 60) + "m"
        seconds = seconds % 60
    }
    if(seconds > 0) {
        result += seconds + "s"
    }
    return result;
}
