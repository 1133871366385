import {AUTH_TOKEN, REFRESH_TOKEN, USER_ID, EXPIRE_LOGIN} from "../constants/local";

export const saveItemInStorage = (key, value, isLocalStorage) => {
  try {
    if (isLocalStorage) {
      key = typeof key === "string" ? key : JSON.stringify(key);

      window.localStorage.setItem(key, value);
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, value);
      window.localStorage.removeItem(key);

    }
  } catch (error) {
    throw error;
  }
}

export const getItemFromStorage = key => {
  try {
    return window.localStorage.getItem(key) || window.sessionStorage.getItem(key);
  } catch (error) {
    throw error;
  }
};

export const saveLoginStorage = (json) => {
  saveItemInStorage(AUTH_TOKEN, json.token, true);
  saveItemInStorage(REFRESH_TOKEN, json.refresh_token, true);
  saveItemInStorage(USER_ID, json?.user?.id, true);
  saveItemInStorage(EXPIRE_LOGIN, new Date().getTime() + 3600000, true);
}
