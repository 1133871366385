import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Search from "../../Common/Search";
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IsVisibleIcon from '@material-ui/icons/VisibilityOutlined';
import EditIcon from '@material-ui/icons/Edit';
import IsVisibleOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from "../../../redux/actions/courses";
import {useHistory} from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import AlertDialog from "../../Common/AlertDialog";
import { apiDeleteCourse } from "../../../redux/api/courses";

const ListCourses = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [coursesList, setCoursesList] = useState([]);
  const getAllCourses = useSelector((state) => state.courses.data);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [idCourse, setIdCourse] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    dispatch(getCourses({ page: newPage + 1 }));
  };

  useEffect(() => {
    dispatch(getCourses());
  }, [dispatch]);

  useEffect(() => {
    setCoursesList(getAllCourses.items)
  }, [getAllCourses.items])

  const TypeEnum = {
    PEOPLE: "Pessoas",
    BUSINESSES: "Empresas",
  };

  const FormatEnum = {
    FACE_TO_FACE: 'Presencial',
    REMOTE: 'Remoto',
    BOTH: 'Ambos'
  };
  
  const handleUpdateCourse = (id) => {
    history.push(`/home/curso/editar/${id}`)
  }
  
  const handleClickOpen = (id) => {
    setOpenFeedback(true);
    setIdCourse(id);
  };

  const handleDeleteCourse = () => {
    apiDeleteCourse(idCourse)
      .then(() => {
        alert('Conteúdo removido com sucesso');
        setOpenFeedback(false);
        dispatch(getCourses());
      })
      .catch(() => {
        alert('Houve um problema no nosso servidor, tente novamente')
      })
  };

  return (
    <Paper style={{ marginTop: 100, width: "80%", padding: 20 }} elevation={3}>
      <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", marginBottom: 40, marginRight: 10 }}>
        <h2 style={{ padding: 10 }}>Lista de Cursos</h2>
        <div style={{display: "flex"}}>
        <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            style={{height: 50, marginRight: 30}}
            onClick ={() => history.push("/criar-curso")}
          >
            Criar curso
          </Button>
        <Search />
        </div>
      </div>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Titulo</TableCell>
            <TableCell>Visivel</TableCell>
            <TableCell>Formato</TableCell>
            <TableCell>Público alvo</TableCell>
            <TableCell align="center" >Visualizar</TableCell>
            <TableCell align="center" >Editar</TableCell>
            <TableCell align="center" >Apagar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {coursesList?.map((row) => (
            <TableRow key={row.id} hover>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.isVisible ? <IsVisibleIcon /> : <IsVisibleOffIcon />}</TableCell>
              <TableCell>{FormatEnum[row.format]}</TableCell>
              <TableCell>{TypeEnum[row.type]}</TableCell>
              <TableCell align="center"><VisibilityIcon /></TableCell>
              <TableCell align="center"><EditIcon onClick={() => handleUpdateCourse(row.id)}/></TableCell>
              <TableCell align="center"><DeleteIcon onClick={() => handleClickOpen(row.id)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TablePagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPage}
          count={getAllCourses?.totalItems}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      {openFeedback && <AlertDialog openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} handleYes={handleDeleteCourse} />}
      </Table>
    </Paper>
  );
}

export default ListCourses;
