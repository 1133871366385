import React from 'react';
import './ExternalContent.scss';

const ExternalContent = ({url}) => {

  return (
    <div className="ExternalContent">
      <iframe title={url}
              key={true}
              width="861px"
              height="577px"
              scrolling="yes"
              frameBorder="no"
              src={url}/>
    </div>
  );
};

export default ExternalContent;
