import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import Gradient from '../../utils/gradient';

const ReportLowestAndHighest = ({ mashieDetailsStore }) => {
  const [reportResponse, setReportResponse] = useState([]);
  let reportListSentence = []

  const getReportLowAndHigh = useCallback(() => {
    let response = {};
    if (mashieDetailsStore.answers) {
      for (let answer of mashieDetailsStore?.answers) {
        for (let scenariosAnswer of answer.scenarios) {
          for (let comparisonsAnswer of scenariosAnswer.comparisons) {
            if (comparisonsAnswer.selectedSentenceId !== null) {
              if (!response[comparisonsAnswer.selectedSentenceId]) {
                response[comparisonsAnswer.selectedSentenceId] = 0
              }
              response[comparisonsAnswer.selectedSentenceId] = response[comparisonsAnswer.selectedSentenceId] + 1
            }
          }
        }
      }
    }
    return setReportResponse(response);
  }, [mashieDetailsStore.answers])

  const renderReport = () => {
    for (let item in reportResponse) {
      mashieDetailsStore?.sentences?.sentences.map((sentence) => {
        if (item === sentence.id) {
          reportListSentence.push({ amount: reportResponse[item], name: sentence.name })
        }
      })
    }
  }
  renderReport()

  useEffect(() => {
    getReportLowAndHigh()
  }, [getReportLowAndHigh]);

  const gradientArray = new Gradient()
    .setColorGradient("#55EFC4","#FFEAA7", "#FF7675")
    .setMidpoint(reportListSentence?.length)
    .getColors();

  return (
    <Paper style={{ margin: "100px 100px 0px 0", padding: 20, width: "min-content" }} elevation={3}>
      <Grid container style={{ display: "flex", padding: 20, minWidth: "538px" }}>
        <Grid item >
          <Typography variant={"h5"}>
            Sentenças mais escolhidas
          </Typography>
          {reportListSentence.length >= 1 && <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell >Quantidade</TableCell>
                <TableCell>Sentença</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportListSentence?.sort((a, b) => b.amount - a.amount).map((sample, key) => {
                return <TableRow key={key} hover>
                  <TableCell style={{ backgroundColor: gradientArray[key] }}>{sample.amount}</TableCell>
                  <TableCell>{sample.name}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReportLowestAndHighest;
