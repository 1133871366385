import {
  CREATE_CONTENT_REQUEST,
  CREATE_CONTENT_REQUEST_FAILED,
  CREATE_CONTENT_REQUEST_SUCCEEDED,
  GET_CONTENTS_REQUEST,
  GET_CONTENTS_REQUEST_FAILED,
  GET_CONTENTS_REQUEST_SUCCEEDED,
  UPDATE_CONTENTS_REQUEST,
  UPDATE_CONTENTS_REQUEST_FAILED,
  UPDATE_CONTENTS_REQUEST_SUCCEEDED,
} from "../constants/contents";

export const contents = (
  state = {
    isFetching: false,
    data: { items: [] },
    error: {},
  },
  action) => {
  let newState = { ...state };

  switch (action.type) {
    case UPDATE_CONTENTS_REQUEST:
    case GET_CONTENTS_REQUEST:
    case CREATE_CONTENT_REQUEST:
      newState.isFetching = true;
      return newState;

    case UPDATE_CONTENTS_REQUEST_SUCCEEDED:
    case GET_CONTENTS_REQUEST_SUCCEEDED:
    case CREATE_CONTENT_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case UPDATE_CONTENTS_REQUEST_FAILED:
    case GET_CONTENTS_REQUEST_FAILED:
    case CREATE_CONTENT_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};
