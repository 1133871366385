import { getPurchaseRequest, getPurchaseRequestFailed, getPurchaseRequestSucceeded } from "../action-creators/purchase";
import { apiGetPurchase } from "../api/purchase";

const requestGetPurchase = (filters) => (dispatch) => {
  dispatch(getPurchaseRequest());
  return apiGetPurchase(filters)
    .then((json) => {
      dispatch(getPurchaseRequestSucceeded(json));
      return Promise.resolve(json);
    })
    .catch((json) => {
      dispatch(getPurchaseRequestFailed(json));
      return Promise.reject(json);
    });
};

export const getAllPurchase = (filters) => (dispatch) => dispatch(requestGetPurchase(filters));
