import {
  GET_CONTENT_ITEM_REQUEST,
  GET_CONTENT_ITEM_REQUEST_FAILED,
  GET_CONTENT_ITEM_REQUEST_SUCCEEDED,
} from "../constants/contentItem";

export const getContentItemRequest = () => ({
  type: GET_CONTENT_ITEM_REQUEST,
});

export const getContentItemRequestSucceeded = (json) => ({
  type: GET_CONTENT_ITEM_REQUEST_SUCCEEDED,
  data: json,
});

export const getContentItemRequestFailed = (json) => ({
  type: GET_CONTENT_ITEM_REQUEST_FAILED,
  error: json,
});
