

function getFinishedAnswers(details) {
  let answers = []
  for (let answer of details?.answers) {
    if (answer.status === 'FINISHED') {
      answers.push(answer)
    }
  }
  return answers
}

function getGroupedAnswersByQuestionId(details, questionId) {
  let grouped = {}
  for (let answer of details.getFinishedAnswers()) {
    for (let questionAnswer of answer.questionAnswers) {
      if (questionAnswer.questionId === questionId) {
        if (!grouped[questionAnswer.answer]) {
          grouped[questionAnswer.answer] = []
        }
        grouped[questionAnswer.answer].push(answer)
      }
    }
  }
  return grouped
}

function getQuestionPrompt(details, questionId) {
  for (let question of details?.settings.questions) {
    if (question.id === questionId) {
      return question.prompt
    }
  }
  return "Questão" // fallback
}

function getSentenceName(details, sentenceId) {
  for (let sentence of details?.sentences.sentences) {
    if (sentence.id === sentenceId) {
      return sentence.name
    }
  }
  return "Sentença" // fallback
}

function getSentenceRank(details, answers) {
  let sentenceRank = {}

  // populateRank
  for (let sentence of details.sentences.sentences) {
    sentenceRank[sentence.id] = 0
  }

  // count clicks
  for (let answer of answers) {
    for (let scenario of answer.scenarios) {
      for (let comparison of scenario.comparisons) {
        if (comparison.selectedSentenceId && comparison.selectedSentenceId !== "null") {
          sentenceRank[comparison.selectedSentenceId] = sentenceRank[comparison.selectedSentenceId] + 1
        }
      }
    }
  }

  // convert to array
  let sentenceRankArray = []
  for (let sentenceId of Object.keys(sentenceRank)) {
    sentenceRankArray.push({
      sentenceId: sentenceId,
      count: sentenceRank[sentenceId],
    })
  }

  // sort array
  sentenceRankArray = sentenceRankArray.sort((a, b) => b.count - a.count)
  return sentenceRankArray
}

export function newMashie(details) {
  details['getFinishedAnswers'] = getFinishedAnswers.bind(this, details)
  details['getGroupedAnswersByQuestionId'] = getGroupedAnswersByQuestionId.bind(this, details)
  details['getQuestionPrompt'] = getQuestionPrompt.bind(this, details)
  details['getSentenceRank'] = getSentenceRank.bind(this, details)
  details['getSentenceName'] = getSentenceName.bind(this, details)
  return details
}
