import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteContent, getContent } from "../../redux/actions/contents";
import Search from "../Common/Search";
import DeleteIcon from '@material-ui/icons/Delete';
import AlertDialog from "../Common/AlertDialog";
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import FullModalContent from "../Common/FullModalContent";

const ListContentPage = () => {
  const getAllContent = useSelector((state) => state.contents?.data);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [removeContentID, setRemoveContentID] = useState("");
  const history = useHistory();
  const [contentsList, setContentsList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openItem, setOpenItem] = useState("");

  useEffect(() => {
    dispatch(getContent());
  }, [dispatch]);

  useEffect(() => {
    setContentsList(getAllContent.items)
  }, [getAllContent.items])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    dispatch(getContent({page: newPage + 1}));
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, getAllContent?.items?.length - page * rowsPerPage);

  const handleClickOpen = (id) => {
    setOpenFeedback(true);
    setRemoveContentID(id);
  };

  const removeContent = () => {
    dispatch(deleteContent(removeContentID))
      .then(() => {
        alert('Conteúdo removido com sucesso');
        setOpenFeedback(false);
        dispatch(getContent())
      })
      .catch(() => {
        alert('Houve um problema no nosso servidor, tente novamente')
      })
  };

  const handleUpdateContent = (id) => {
    history.push(`/home/conteudos/editar/${id}`)
  }  

  const openModalView = (row) => {
    setOpenModal(true);
    setOpenItem(row);
  };

  return (
    <Paper style={{ marginTop: 100, width: "80%", padding: 20 }} elevation={3}>
      <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", marginBottom: 40, marginRight: 10 }}>
        <h2 style={{ padding: 10 }}>Lista de Conteúdos</h2>
        <Search />
      </div>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Titulo</TableCell>
            <TableCell>Autor</TableCell>
            <TableCell>Plataforma</TableCell>
            <TableCell>Tipo de mídia</TableCell>
            <TableCell align="center" >Visualizar</TableCell>
            <TableCell align="center" >Editar</TableCell>
            <TableCell align="center" >Apagar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contentsList?.map((row) => (
              <TableRow key={row.id} hover>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.author.username}</TableCell>
                <TableCell>{row.platform}</TableCell>
                <TableCell>{row.mediaType}</TableCell>
                <TableCell align="center"><VisibilityIcon onClick={() => openModalView(row.id)}/></TableCell>
                <TableCell align="center"><EditIcon onClick={() => handleUpdateContent(row.id)}/></TableCell>
                <TableCell align="center"><DeleteIcon onClick={() => handleClickOpen(row.id)} /></TableCell>
              </TableRow>
            ))
            }
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * (rowsPerPage - getAllContent.items?.length)}}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[12, 24]}
          rowsPerPage={rowsPerPage}
          count={getAllContent.totalItems}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
        />
      </Table>
      {openFeedback && <AlertDialog openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} handleYes={removeContent} />}
      {openModal && <FullModalContent open={openModal} setOpen={setOpenModal} item={openItem}/>}
    </Paper>
  );
};

export default ListContentPage;
