import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from 'react';
import AnswerTableRowScenario from "../AnswerTableRowScenario/AnswerTableRowScenario";

const AnswerTableRow = ({ mashie, mashieSubmission, answer }) => {
  const [open, setOpen] = React.useState(false);

  const scenarioComparator = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <TableRow hover>
        <TableCell width={30}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {answer?.questionAnswers?.[0] && <TableCell>{answer?.questionAnswers?.[0]?.answer}</TableCell>}
        <TableCell>{answer?.id}</TableCell>
        <TableCell align="center">{answer?.status === "FINISHED" ?
          <span style={{ color: '#1a7c00' }}>Finalizado</span> :
          <span style={{ color: '#c1b800' }}>Em progresso</span>}</TableCell>
        <TableCell width={100} align="center">{answer && new Date(answer.startedAt).toLocaleString()}</TableCell>
        <TableCell width={100}
          align="center">{answer?.endedAt && new Date(answer.endedAt).toLocaleString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {!answer?.questionAnswers || !answer?.questionAnswers.length &&
                <Typography variant="subtitle1" gutterBottom component="div">
                  Não foram respondidas perguntas.
                </Typography>
              }
              {answer?.questionAnswers && answer.questionAnswers.length > 0 && <>
                <Typography variant="h6" gutterBottom component="div">
                  Perguntas respondidas:
                </Typography>
                {answer.questionAnswers.map(questionAnswer => {
                  return <>
                    <Typography variant="subtitle2" gutterBottom component="div">
                      {mashie.getQuestionById(questionAnswer.questionId).prompt}
                    </Typography>
                    <Typography variant="body2" gutterBottom component="div">
                      - {questionAnswer.answer}
                    </Typography>
                  </>
                })}
              </>}
              {answer?.scenarios.sort(scenarioComparator).map(scenario => {
                return <AnswerTableRowScenario mashie={mashie} mashieSubmission={mashieSubmission} answer={answer} scenario={scenario} />
              })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AnswerTableRow;
