import { AUTH_TOKEN } from "../constants/local";
import { getItemFromStorage } from "../helper/storage";
import { baseUrl, buildHeaders, urlFilters } from "../redux/api/common";

export const apiApplicationList = async (filters) => {
  const token = getItemFromStorage(AUTH_TOKEN);
  try {
    const response = await fetch(urlFilters(`${baseUrl}/ttr/project/submissions`, filters), {
      method: "GET",
      headers: buildHeaders(token)
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiSubmitedApplication = async (payload) => {
  const token = getItemFromStorage(AUTH_TOKEN);

  try {
    const response = await fetch(`${baseUrl}/ttr/project/submissions/review`, {
      method: "PUT",
      headers: buildHeaders(token),
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      const errorData = await response.json();

      throw new Error(`Request failed with status: ${errorData.error}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};
