import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "../CreateCourse/CreateCourse.scss";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  IconButton
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/AddRounded";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import TooltipIcon from "@material-ui/icons/HelpOutline";
import { getCourse, getCourses, updateCourse } from "../../../redux/actions/courses";
import { createModule, updateModule } from "../../../redux/actions/modules";
import CircularProgress from '@mui/material/CircularProgress';
import { apiDeleteModule } from "../../../redux/api/modules";
import { useSelector } from "react-redux";
import SearchTagComment from "../../Pages/EditContent/SearchTagComment/SearchTagComment";
import { getAllProducts } from "../../../redux/actions/product";

const EditCourse = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.data.items);
  const { id } = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [format, setFormat] = useState("");
  const [area, setArea] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [cardDescription, setCardDescription] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [framePresentation, setFramePresentantation] = useState("");
  const [cardImage, setCardImage] = useState({ url: "", alt: "" });
  const [imageCourse, setImageCourse] = useState({ url: "", alt: "" });
  const [estimateDuration, setEstimateDuration] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [investment, setInvestment] = useState("");
  const [externalUrl, setExternalUrl] = useState("");
  const [idCourse, setIdCourse] = useState("");
  const [moduleValues, setModuleValues] = useState([])
  const [loadingPage, setLoadingPage] = useState(false);
  const [nextClass, setNextClass] = useState("");
  const [listCourse, setListCourse] = useState([]);
  const [listCourseID, setListCourseID] = useState([]);
  const [valueInput, setValueInput] = useState("");
  const [nameCourse, setNameCourse] = useState("");
  const [referencesAll, setReferencesAll] = useState([])
  const [allCourses, setAllCourses] = useState([])
  const [shoppingId, setShoppingId] = useState("");

  useEffect(() => {
    dispatch(getAllProducts());
  }, []);

  const resultInput = !valueInput ? allCourses :
    allCourses?.filter(item => item.name.toLowerCase().includes(valueInput.toLocaleLowerCase()))
      .filter(item => item.name !== valueInput);

  const cards = resultInput?.slice(0, 4)

  const handleClick = (name, edit) => {
    !edit && setValueInput(name)
  }

  const removeReference = (name) => {
    setNameCourse(name.name)
    const index = listCourse.indexOf(name);
    if (index !== -1) listCourse.splice(index, 1)
  }

  const removeReferenceID = (id) => {
    listCourseID?.splice(listCourseID.indexOf(id), 1)
  }

  useEffect(() => {
    dispatch(getCourses()).then((json) => setAllCourses(json.items))
    dispatch(getCourse(id)).then((json) => {
      setName(json.name)
      setType(json.type)
      setFormat(json.format)
      setArea(json.area)
      setCategory(json.category)
      setDescription(json.description)
      setDescriptionTitle(json.descriptionTitle || "")
      setCardDescription(json.cardDescription || "")
      setFramePresentantation(json?.framePresentation || "")
      setCardImage(json?.cardImage || { url: "", alt: "" })
      setImageCourse(json.imageCourse)
      setEstimateDuration(json.estimateDuration)
      setTargetAudience(json.targetAudience)
      setInvestment(json.investment || "")
      setExternalUrl(json?.externalUrl || "")
      setModuleValues(json.modules)
      setIsVisible(json.isVisible)
      setNextClass(json?.nextClass || "")
      setIdCourse(json.id)
      setReferencesAll(json?.references)
      setShoppingId(json?.shoppingId || "")
    })
  }, [dispatch, id]);

  useEffect(() => {
    referencesAll?.map((item) => {
      dispatch(getCourse(item)).then((json) => {
        listCourse.push({ name: json?.name, id: json?.id })
        listCourseID.push(json?.id)
        handleClick(json?.name)
      })
    })
  }, [referencesAll]);

  let handleChange = (i, e) => {
    let newModuleValues = [...moduleValues];
    newModuleValues[i][e.target.name] = e.target.value;
    setModuleValues(newModuleValues);
  }

  let addModuleields = () => {
    setModuleValues([...moduleValues, { name: "", description: "" }])
  }

  let removeModuleFields = (i) => {
    if (moduleValues[i].id) {
      apiDeleteModule(moduleValues[i].id).then()
    }

    let newModuleValues = [...moduleValues];
    newModuleValues.splice(i, 1);
    setModuleValues(newModuleValues)
  }

  const handleUpdateCourse = (e) => {
    e.preventDefault()

    const payload = {
      name: name,
      type: type,
      format: format,
      area: area,
      category: category,
      description: description,
      descriptionTitle,
      cardDescription,
      framePresentation: framePresentation,
      nextClass,
      imageCourse,
      cardImage,
      estimateDuration,
      targetAudience,
      investment: investment,
      externalUrl,
      isVisible,
      shoppingId,
      references: listCourseID
    }

    setLoadingPage(true)
    dispatch(updateCourse(payload, idCourse))
      .then((json) => {
        (moduleValues.length >= 1 &&
          moduleValues.map((item, index) => {
            let objecto = { name: item["name"], description: item["description"] }
            let finalLoading = !item["id"] ? dispatch(createModule(objecto, idCourse)) :
              dispatch(updateModule(objecto, item.id))

            Promise.all([finalLoading]).then(() => {
              setLoadingPage(false)
              window.location.reload(false);
            })
          }))
        setLoadingPage(false)
      })

      .catch(() => {
        alert("Erro, tente novamente")
      });
  }

  return (
    <form onSubmit={handleUpdateCourse}>
      <Paper style={{ marginTop: 100, width: "70%", padding: 40 }} elevation={3}>
        <h2>Criar curso</h2>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              required
              label="Nome"
              style={{ width: 420, marginBottom: 30 }}
              value={name}
              InputProps={{
                endAdornment: (
                  <Tooltip placement="top-left" title="Adicione '\n' entre o texto para quebra de linha">
                    <IconButton edge="start" color="none">
                      <TooltipIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              required
              label="Descrição"
              variant="filled"
              multiline
              rows={3}
              style={{ width: 420, marginBottom: 30 }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              label="Descrição da página "
              variant="filled"
              multiline
              rows={2}
              style={{ width: 420, marginBottom: 30 }}
              value={descriptionTitle}
              onChange={(e) => setDescriptionTitle(e.target.value)}
            />
            <TextField
              required
              label="Descrição do pequeno card"
              variant="filled"
              multiline
              rows={1}
              style={{ width: 420, marginBottom: 30 }}
              value={cardDescription}
              onChange={(e) => setCardDescription(e.target.value)}
            />
            <TextField
              label="Imagem do card"
              InputProps={{
                endAdornment: (
                  <Tooltip placement="top-left" title="URL da imagem">
                    <IconButton edge="start" color="none">
                      <TooltipIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              style={{ width: 200, marginBottom: 30 }}
              value={cardImage?.url}
              onChange={(e) => setCardImage({ ...cardImage, url: e.target.value })}
            />
            <TextField
              label="Imagem do card"
              InputProps={{
                endAdornment: (
                  <Tooltip placement="top-left" title="Descrição da imagem">
                    <IconButton edge="start" color="none">
                      <TooltipIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              style={{ width: 200, marginBottom: 30, marginRight: 20 }}
              value={cardImage?.alt}
              onChange={(e) => setCardImage({ ...cardImage, alt: e.target.value })}
            />
            <TextField
              label="URL de vídeo de apresentação"
              style={{ width: 250, marginBottom: 30 }}
              value={framePresentation}
              onChange={(e) => setFramePresentantation(e.target.value)}

            />
            <div>
              <TextField
                label="Imagem de curso"
                style={{ width: 200, marginBottom: 30 }}
                value={imageCourse.url}
                InputProps={{
                  endAdornment: (
                    <Tooltip placement="top-left" title="URL da imagem">
                      <IconButton edge="start" color="none">
                        <TooltipIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
                onChange={(e) => setImageCourse({ ...imageCourse, url: e.target.value })}
              />
              <TextField
                label="Imagem de curso"
                InputProps={{
                  endAdornment: (
                    <Tooltip placement="top-left" title="Descrição da imagem">
                      <IconButton edge="start" color="none">
                        <TooltipIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
                style={{ width: 200, marginBottom: 30 }}
                value={imageCourse.alt}
                onChange={(e) => setImageCourse({ ...imageCourse, alt: e.target.value })}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              style={{ marginTop: 40 }}
              onClick={() => addModuleields()}
            >
              Adicionar módulo
            </Button>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginBottom: 30 }}>
              <FormControl style={{ width: 200, marginRight: 30 }} required >
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Tipo"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value={"PEOPLE"}>Pessoa</MenuItem>
                  <MenuItem value={"BUSINESSES"}>Empresa</MenuItem>
                </Select>
              </FormControl>
              <FormControl required style={{ width: 200 }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Formato de Curso
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Formato do curso"
                  value={format}
                  onChange={(e) => setFormat(e.target.value)}
                >
                  <MenuItem value={"FACE_TO_FACE"}>Presencial</MenuItem>
                  <MenuItem value={"REMOTE"}>Remoto</MenuItem>
                  <MenuItem value={"BOTH"}>Ambos</MenuItem>
                </Select>
              </FormControl>
            </div>
            <FormControl style={{ width: 200, marginRight: 30 }} required>
              <InputLabel id="demo-simple-select-outlined-label">
                Categoria
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Categoria"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value={"EXPERIENCE"}>Experiência</MenuItem>
                <MenuItem value={"FORMATION"}>Formação</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: 200 }} required>
              <InputLabel id="demo-simple-select-outlined-label">
                Area
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Area"
                value={area}
                onChange={(e) => setArea(e.target.value)}
              >
                <MenuItem value={"TECH"}>Tech</MenuItem>
                <MenuItem value={"AGIL"}>Ágil</MenuItem>
                <MenuItem value={"DIGITAL"}>Digítal</MenuItem>
                <MenuItem value={"INOVACAO"}>Inovação</MenuItem>
                <MenuItem value={"DADOS"}>Dados</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              label="Duração do curso"
              style={{ width: 420, marginBottom: 30, marginTop: 30 }}
              value={estimateDuration}
              onChange={(e) => setEstimateDuration(e.target.value)}
            />
            <TextField
              required
              label="Para quem é o curso"
              style={{ width: 420, marginBottom: 30 }}
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
            />
            <TextField
              label="Investimento"
              style={{ width: 420, marginBottom: 30 }}
              value={investment}
              onChange={(e) => setInvestment(e.target.value)}
            />
            <TextField
              label="Link redirecional do curso"
              style={{ width: 420, marginBottom: 30 }}
              value={externalUrl}
              onChange={(e) => setExternalUrl(e.target.value)}
            />
            <TextField
              label="Próxima turma"
              style={{ width: 420, marginBottom: 30 }}
              value={nextClass}
              onChange={(e) => setNextClass(e.target.value)}
            />
            <FormControl
              style={{ width: 420, marginRight: 30 }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Produto referente
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={shoppingId}
                onChange={(e) => setShoppingId(e.target.value)}
              >
                {products && products?.map((product) =>
                  <MenuItem key={product?.id} value={product.id}>{product.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            <div>
              <FormControlLabel style={{ marginTop: 25 }}
                control={<Switch name="rascunho" color="primary" />}
                label="Visível"
                checked={isVisible}
                onChange={(e) => setIsVisible(e.target.checked)}
              />
            </div>
          </Grid>
        </Grid>
        {moduleValues.map((element, index) => (
          moduleValues ? <div className="form-inline" key={index}>
            <label>Título</label>
            <input type="text" name="name" value={element.name || ""} onChange={e => handleChange(index, e)} />
            <label>Descrição</label>
            <textarea rows="3" type="text" name="description" value={element.description || ""} onChange={e => handleChange(index, e)} />
            <button type="button" className="button remove" onClick={() => removeModuleFields(index)}><RemoveIcon /> Remover </button>
          </div> : null
        ))}
        <Grid style={{ marginTop: 50 }}>
          <TextField
            label="Adicionar cursos referentes"
            InputProps={{
              endAdornment: (
                <Tooltip placement="top-left" title="Lista de cursos relacionados com esse">
                  <IconButton edge="start" color="none">
                    <TooltipIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
            value={valueInput}
            style={{ width: 420, marginBottom: 30 }}
            type="text"
            onChange={(e) => {
              setValueInput(e.target.value)
            }}
          />
          {valueInput && (
            cards?.map((item, key) =>
              <SearchTagComment item={item} key={key} onClick={() => {
                listCourse.push({ name: item.name, id: item.id })
                listCourseID.push(item.id)
                handleClick(item?.name)
                setValueInput("")
              }} />
            )
          )}
          <div className="container-delete-tags">
            {listCourse?.map((item, key) => {
              return <Button key={key} variant="contained"
                onClick={() => {
                  removeReference(item)
                  removeReferenceID(item.id)
                }}
              >{item?.name}</Button>
            })}
          </div>
        </Grid>
        {!loadingPage ? <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          startIcon={<SaveIcon />}
          style={{ marginTop: 40 }}
        >
          Salvar
        </Button> : <CircularProgress style={{ marginTop: 40 }} />}
      </Paper>
    </form>
  )
};

export default EditCourse;
