import React, { useEffect, useState } from 'react';
import './Notification.scss';

const Notification = ({ message, duration }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    setIsVisible(true);
    let startTime;
    let animationFrameId;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;
      const newProgress = 100 - (elapsedTime / duration) * 100;

      setProgress(Math.max(0, newProgress));

      if (elapsedTime < duration) {
        animationFrameId = requestAnimationFrame(animate);
      } else {
        setIsVisible(false);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    const cleanup = () => {
      cancelAnimationFrame(animationFrameId);
    };

    const timeout = setTimeout(() => {
      cleanup();
    }, duration);

    return () => {
      cleanup();
      clearTimeout(timeout);
    };
  }, [duration]);

  return isVisible ? (
    <div className="Notification">
      {message}
      <div className="progress-bar" style={{ width: `${progress}%` }} />
    </div>
  ) : null;
};

export default Notification;