import {
  CREATE_MASHIE_REQUEST,
  CREATE_MASHIE_REQUEST_FAILED,
  CREATE_MASHIE_REQUEST_SUCCEEDED,
  GET_MASHIE_DETAILS_REQUEST,
  GET_MASHIE_DETAILS_REQUEST_FAILED,
  GET_MASHIE_DETAILS_REQUEST_SUCCEEDED,
  GET_MASHIE_SUBMISSIONS_REQUEST,
  GET_MASHIE_SUBMISSIONS_REQUEST_FAILED,
  GET_MASHIE_SUBMISSIONS_REQUEST_SUCCEEDED,
  LIST_MASHIES_REQUEST,
  LIST_MASHIES_REQUEST_FAILED,
  LIST_MASHIES_REQUEST_SUCCEEDED
} from "../constants/mashie";

export const mashie = (
  state = { isFetching: false, data: { items: [] }, error: {}, }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case LIST_MASHIES_REQUEST:
    case GET_MASHIE_DETAILS_REQUEST:
    case CREATE_MASHIE_REQUEST:
      newState.isFetching = true;
      return newState;

    case LIST_MASHIES_REQUEST_SUCCEEDED:
    case GET_MASHIE_DETAILS_REQUEST_SUCCEEDED:
    case CREATE_MASHIE_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case LIST_MASHIES_REQUEST_FAILED:
    case GET_MASHIE_DETAILS_REQUEST_FAILED:
    case CREATE_MASHIE_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};

export const mashieSubmissions = (
  state = { isFetching: false, data: { items: [] }, error: {}, }, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_MASHIE_SUBMISSIONS_REQUEST:
      newState.isFetching = true;
      return newState;

    case GET_MASHIE_SUBMISSIONS_REQUEST_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_MASHIE_SUBMISSIONS_REQUEST_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
};