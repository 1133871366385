import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAdmin, getAdmin } from '../../redux/actions/admin';
import AdminsTable from '../Common/AdminsTable';
import Input from '../Common/Input';

const AdminPage = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const handleSignUp = () => {
    const payload = {
      email,
    };
    dispatch(addAdmin(payload))
      .then(() => {
        alert('Email cadastrado');
        dispatch(getAdmin())
      })
      .catch(() => {
        alert("Houve um problema no nosso servidor");
      })
  };

  return (
    <div style={{ marginTop: 100, width: '80%' }}>
      <h2>Admins do sistema</h2>
      <AdminsTable />
      <Input value={email} setValue={setEmail} handleSend={handleSignUp} text={"um novo admin"} input={"email"}/>
    </div>
  )
};

export default AdminPage;
