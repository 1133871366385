import {
  AppBar,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EmailIcon from "@material-ui/icons/Email";
import LocalMallCartIcon from "@material-ui/icons/LocalMall";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PersonOutlineIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TocIcon from "@material-ui/icons/Toc";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import MastertechLogo from "../../assets/img/logo-mastertech-mini.png";
import AdminPage from "../Pages/AdminPage";
import CreateContentPage from "../Pages/CreateContent/CreateContent";
import EditContent from "../Pages/EditContent/EditContent";
import ListContentPage from "../Pages/ListContentPage";
import TagsPage from "../Pages/TagsPage";
import SendEmail from "../Pages/email/SendEmail/SendEmail";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import CreateCourse from "../course/CreateCourse/CreateCourse";
import EditCourse from "../course/EditCourse/EditCourse";
import ListCourses from "../course/ListCourses/ListCourses";
import CreateProduct from "../ecommerce/CreateProduct/CreateProduct";
import EditProduct from "../ecommerce/EditProduct/EditProduct";
import Product from "../ecommerce/Product/Product";
import Purchase from "../ecommerce/Purchase/Purchase";
import ListMashies from "../mashie/ListMashies";
import CreateMashie from "../mashie/mashieDetails/CreateMashie/CreateMashie";
import ViewMashie from "../mashie/mashieDetails/ViewMashie/ViewMashie";
import MashieReport from "../mashie/mashieReport/MashieReport/MashieReport";
import "./Menu.scss";
import Projects from "../match/Projects/Projects";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: 50,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    marginTop: 40,
    width: drawerWidth,
  },
}));

const Menu = props => {
  const [openEcommerce, setOpenEcommerce] = useState(false)
  const { history } = props;
  const classes = useStyles();

  const menuList = [
    {
      text: "Acessos",
      icon: <PersonOutlineIcon />,
      onClick: () => history.push("/acessos"),
    },
    {
      text: "Tags",
      icon: <LocalOfferIcon />,
      onClick: () => history.push("/tags"),
    },
    {
      text: "Conteúdos",
      icon: <TocIcon />,
      onClick: () => history.push("/conteudos"),
    },
    {
      text: "Criar conteúdo",
      icon: <AddCircleIcon />,
      onClick: () => history.push("/criar-conteudo"),
    },
    {
      text: "Mashie",
      icon: <TocIcon />,
      onClick: () => history.push("/mashies"),
    },
    {
      text: "Cursos",
      icon: <TocIcon />,
      onClick: () => history.push("/cursos"),
    },
    {
      text: "E-mails",
      icon: <EmailIcon />,
      onClick: () => history.push("/enviar-email"),
    },
    {
      text: "Ecommerce",
      icon: <ShoppingCartIcon />,
      onClick: () => setOpenEcommerce(!openEcommerce),
    },
    {
      text: "Projetos do Match",
      icon: <AssignmentIcon />,
      onClick: () => history.push("/match/projetos"),
    },
  ];

  const ecommerceList = [
    {
      text: "Compras",
      icon: <PersonOutlineIcon />,
      onClick: () => history.push("/ecommerce/compras"),
    },
    {
      text: "Produtos",
      icon: <LocalMallCartIcon />,
      onClick: () => history.push("/ecommerce/produtos"),
    }
  ];

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className="Navbar">
          <a href="/home" className="navContent" rel="noopener noreferrer">
            <img src={MastertechLogo} alt="logo" />
            <p>
              MASTERTECH<span> ADMIN</span>
            </p>
          </a>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div>
            <List className="MenuContent">
              {menuList.map((item) => {
                const { text, icon, onClick } = item;
                return (
                  <ListItem button key={text} onClick={onClick}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                );
              })}
              <Collapse in={openEcommerce}>
                <List className="MenuContent">
                  {ecommerceList.map((item) => {
                    const { text, icon, onClick } = item;
                    return (
                      <ListItem button key={text} onClick={onClick}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </List>
          </div>
        </Drawer>
        <PrivateRoute path="/acessos"><AdminPage /></PrivateRoute>
        <PrivateRoute path="/tags"><TagsPage /></PrivateRoute>
        <PrivateRoute path="/conteudos"><ListContentPage /></PrivateRoute>
        <PrivateRoute path="/criar-conteudo"><CreateContentPage /></PrivateRoute>
        <PrivateRoute path="/enviar-email"><SendEmail /></PrivateRoute>
        <PrivateRoute path="/mashies"><ListMashies /></PrivateRoute>
        <PrivateRoute path="/mashie/:id"><ViewMashie /></PrivateRoute>
        <PrivateRoute path="/home/conteudos/editar/:id"><EditContent /></PrivateRoute>
        <PrivateRoute path="/criar-mashie"><CreateMashie /></PrivateRoute>
        <PrivateRoute path="/report/mashie/:id"><MashieReport /></PrivateRoute>
        <PrivateRoute path="/cursos"><ListCourses /></PrivateRoute>
        <PrivateRoute path="/criar-curso"><CreateCourse /></PrivateRoute>
        <PrivateRoute path="/home/curso/editar/:id"><EditCourse /></PrivateRoute>
        <PrivateRoute path="/ecommerce/compras"><Purchase /></PrivateRoute>
        <PrivateRoute path="/ecommerce/produtos"><Product /></PrivateRoute>
        <PrivateRoute path="/ecommerce/criar-produto"><CreateProduct /></PrivateRoute>
        <PrivateRoute path="/ecommerce/produto/editar/:id"><EditProduct /></PrivateRoute>
        <PrivateRoute path="/match/projetos"><Projects /></PrivateRoute>
      </div>
    </>
  );
};

export default withRouter(Menu);
