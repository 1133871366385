import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiUserSignUp = payload => {
  return fetch(`${baseUrl}/user/user`, {
    method: "POST",
    headers: buildHeaders(),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
    .then((json) => {
      return Promise.resolve(json);
    })
    .catch(error => Promise.reject(error));
}

export const apiForgotPasswordRequest = (token, email) => {
  return fetch(`${baseUrl}/user/user/password/recovery`, {
    method: "POST",
    headers: buildHeaders(token),
    body: JSON.stringify(email)
  }).then(getResponseOrThrow)
  .then(json => {
    return Promise.resolve(json);
  })
  .catch(error => {
    return Promise.reject(error);
  })
};

export const apiNewPasswordRequest = (token,code, payload) => {
  return fetch(`${baseUrl}/user/user/password/recovery/${code}`, {
    method: "PUT",
    headers: buildHeaders(token),
    body: JSON.stringify(payload)
  }).then(getResponseOrThrow)
  .then(json => {
    return Promise.resolve(json);
  })
  .catch(error => {
    return Promise.reject(error);
  })
};
