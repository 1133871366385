import React, { useState } from 'react';
import {
  Button,
  Card,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { removeSpecialCharacters } from "../../../../helper/removeSpecialCharacters";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from "@material-ui/icons/Delete";
import AddSentences from "./AddSentences/AddSentences";
import AddQuestions from "./AddQuestions/AddQuestions";
import { useDispatch } from "react-redux";
import { createMashie } from "../../../../redux/actions/mashie";
import SentenceWithCSV from "./SentenceWithCSV/SentenceWithCSV";

const CreateMashie = () => {
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([""]);
  const [sentencesList, setSentencesList] = useState([""]);
  const [scenarioList, setScenarioList] = useState([""]);
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [platform, setPlatform] = useState("");
  const [valueInput, setValueInput] = useState("");
  const [listMetadata, setListMetadata] = useState([]);
  const [numberSentences, setNumberSentences] = useState();
  const [activeSentences, setActiveSentences] = useState(false);
  const [starterQuestionList, setStarterQuestionList] = useState([]);
  const [sentences, setSentences] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [sentenceCSV, setSentenceCSV] = useState([]);
  const [metadataCSV, setMetadataCSV] = useState([]);
  const [creationOption, setCreationOption] = useState("");
  const [thankyouMessage, setThankYouMessage] = useState("");
  const [themeColor, setThemeColor] = useState("");

  const addQuestion = () => {
    const questionList = [...questions, ""];
    setQuestions(questionList);
  };

  const handleChangeScenario = (value, index, keyName) => {
    const questionsCopy = [...scenarios];
    if (questionsCopy[index]) {
      questionsCopy[index][keyName] = value
    } else {
      const question = {}
      question[keyName] = value
      questionsCopy.push(question)
    }
    setScenarios(questionsCopy)
  }

  const addQuestions = (value) => {
    const listSentences = [];
    listSentences.length = value;
    listSentences.fill("")
    setSentencesList(listSentences);

    const listScenario = [];
    listScenario.length = Math.log2(listSentences.length) - 2
    listScenario.fill("")
    setScenarioList(listScenario);
  };

  const handleCreateMetaData = (value) => {
    if (value) {
      const noSpecialCharacters = removeSpecialCharacters(valueInput)
      if (noSpecialCharacters.length < 2) {
        setValueInput(removeSpecialCharacters(valueInput))
      } else if (noSpecialCharacters.length > 40) {
        setValueInput(removeSpecialCharacters(valueInput))
      } else {
        setValueInput("")
        listMetadata.push(noSpecialCharacters)
      }
    }
  }

  const handleCreateMashie = () => {
    const payload = {
      code,
      name: title,
      description,
      kind: platform,
      settings: {
        thankyouMessage,
        themeColor,
        requiredLogin: "false",
        questions: starterQuestionList,
        scenarioPrompts: scenarios.map(scenario => scenario.name)
      },
      sentences: {}
    }
    if (metadataCSV.length >= 1) {
      payload.sentences.metadataNames = metadataCSV
    }
    if (listMetadata.length >= 1) {
      payload.sentences.metadataNames = listMetadata
    }
    if (sentenceCSV.length >= 1) {
      payload.sentences.sentences = sentenceCSV
    }
    if (sentences.length >= 1) {
      payload.sentences.sentences = sentences
    }
    dispatch(createMashie(payload))
      .then(() => {
        alert("Conteúdo criado com sucesso!")
        window.location.reload()
      })
      .catch(() => {
        alert("Erro, tente novamente")
      });
  }

  const removeMetaData = (name) => {
    setValueInput(name)
    listMetadata.splice(listMetadata.indexOf(name), 1)
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: "40px" }}>
      <Paper style={{ marginTop: 100, padding: 40 }}>
        <Typography variant={"h4"}>
          Informações iniciais
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={7}>
            <div>
              <TextField
                label="Título"
                style={{ width: 320, marginBottom: 30 }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                type='color'
                label="Cor Padrão"
                style={{ width: 220, marginBottom: 30, marginLeft: 20 }}
                value={themeColor}
                onChange={(e) => setThemeColor(e.target.value)}
              />
            </div>
            <div>
              <TextField
                label="Texto de finalização"
                style={{ width: 320, marginBottom: 30 }}
                value={thankyouMessage}
                onChange={(e) => setThankYouMessage(e.target.value)}
              />
              <TextField
                label="Código"
                style={{ width: 220, marginBottom: 30, marginLeft: 20 }}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div style={{ marginBottom: 30 }}>
              <FormControl
                style={{ width: 250, marginRight: 30 }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Distribuição de Sentenças
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Distribuição de Sentenças"
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                >
                  <MenuItem value="CASUAL">Casual</MenuItem>
                  <MenuItem value="DUPLICATED">Duplicado</MenuItem>
                  <MenuItem value="BALANCED">Balanceado</MenuItem>
                </Select>
              </FormControl>
              <div style={{ marginTop: "20px", width: "75%" }}>
                <strong>Distribuição de Sentenças:</strong>
                <p>Casual - As sentenças são distribuídas aleatoriamente, sem ponderar</p>
                <p>Duplicado - As sentenças são duplicadas e depois distribuídas aleatoriamente</p>
                <p>Balanceado - Utiliza um algoritmo para evitar que as sentenças sejam comparadas com o mesmo par
                  várias vezes</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Descrição"
              variant="filled"
              multiline
              minRows={19}
              style={{ width: 420, marginBottom: 30 }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{ marginTop: 30, padding: 40 }}>
        <Grid container justifyContent="space-between">
          <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between", width: "100%" }}>
            <Typography variant="h4">
              Perguntas iniciais
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={addQuestion}
              startIcon={<AddCircleIcon />}
              style={{ marginBottom: "40px" }}
            >
              nova pergunta
            </Button>
          </div>
        </Grid>
        <Grid container direction="column"
          alignItems="flex-start">
          <Grid container xs={12}>
            <AddQuestions
              starterQuestionList={starterQuestionList}
              setStarterQuestionList={setStarterQuestionList}
              setQuestions={setQuestions}
              questions={questions} />
          </Grid>
        </Grid>
      </Paper>
      {creationOption.length < 1 ? <Paper style={{ marginTop: 30, padding: 40 }}>
        <Grid container justifyContent={"space-between"}>
          <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between", width: "100%" }}>
            <Typography variant={"h4"}>
              Modo de adição de sentenças
            </Typography>
            <div>
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 300, marginRight: "20px" }}
                  onClick={() => setCreationOption("manual")}
                  startIcon={<AddCircleIcon />}
                >
                  Manual
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 300 }}
                  onClick={() => setCreationOption("cvs")}
                  startIcon={<AddCircleIcon />}
                >
                  CVS
                </Button>
              </Grid>
            </div>
          </div>
        </Grid>
      </Paper> : ""}
      {creationOption === "cvs" ? <Paper style={{ marginTop: 30, padding: 40 }}>
        <SentenceWithCSV sentenceCSV={sentenceCSV} setSentenceCSV={setSentenceCSV} setMetadataCSV={setMetadataCSV} />
      </Paper> : creationOption === "manual" ?
        <Paper style={{ marginTop: 30, padding: 40 }}>
          <Grid container justifyContent="space-between">
            <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between", width: "100%" }}>
              <Typography variant="h4">
                Sentenças
              </Typography>
              <div>
                {!activeSentences && listMetadata.length >= 1 && <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: 300, marginBottom: "20px" }}
                    onClick={() => setActiveSentences(true)}
                    startIcon={<AddCircleIcon />}
                  >
                    Aplicar
                  </Button>
                </Grid>}
                {!activeSentences && listMetadata.length >= 1 ? <FormControl
                  style={{ width: 250, marginRight: 30 }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Quantidade de Sentenças
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Quantidade de Sentenças"
                    value={numberSentences}
                    onChange={(e) => addQuestions(e.target.value)}
                  >
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                    <MenuItem value={32}>32</MenuItem>
                    <MenuItem value={64}>64</MenuItem>
                  </Select>
                </FormControl> : ""}
              </div>
              {!activeSentences && <div>
                <Input
                  value={valueInput}
                  type="text"
                  placeholder="Adicionar metadado"
                  onChange={(e) => {
                    setValueInput(e.target.value)

                  }} />
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleCreateMetaData(true)}
                  startIcon={<AddCircleIcon />}
                >
                  Criar
                </Button>
                <div className="container-delete-tags" style={{ marginTop: "10px" }}>
                  {listMetadata.map((item, key) => {
                    return (
                      <Button style={{ textTransform: 'none' }}
                        key={key}
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          removeMetaData(item)
                        }}>
                        {item}
                      </Button>)
                  })}
                </div>
              </div>}
            </div>
          </Grid>
          <Grid container justifyContent="center"
          >
            {activeSentences && sentencesList.length >= 4 &&
              <AddSentences
                sentences={sentences}
                setSentences={setSentences}
                listMetadata={listMetadata}
                sentencesList={sentencesList} />}
          </Grid>
        </Paper> : ""}

      {sentencesList.length >= 4 && activeSentences &&
        <Paper style={{ marginTop: 30, padding: 40 }}>
          <Grid container justifyContent="space-between">
            <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between", width: "100%" }}>
              <Typography variant="h4">
                Texto de Cenários
              </Typography>
            </div>
          </Grid>
          <Grid container justifyContent="center">
            {scenarioList.map((scenario, scenarioIndex) => {
              return (
                <Card style={{ marginTop: "20px", padding: "40px" }}>
                  <div key={scenarioIndex}
                    style={{ display: "flex", alignItems: "flex-end" }}>
                    <TextField key={scenarioIndex}
                      type="text"
                      label={`Cenário ${scenarioIndex + 1}`}
                      variant="outlined"
                      style={{ width: 400 }}
                      value={scenarios[scenarioIndex]?.name || ""}
                      onChange={(value) => handleChangeScenario(value.target.value, scenarioIndex, "name")}
                      placeholder={`Cenário ${scenarioIndex + 1}`}
                    />
                  </div>
                </Card>)
            })}
          </Grid>
        </Paper>}
      {(sentenceCSV.length > 1 || sentences.length >= 4) && <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "30px" }}
        size="small"
        onClick={() => handleCreateMashie()}
        startIcon={<AddCircleIcon />}
      >
        Criar
      </Button>}
    </div>
  );
};

export default CreateMashie;
