import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { admins } from './../src/redux/reducers/admin';
import { content } from './../src/redux/reducers/contentItem';
import { contents } from './../src/redux/reducers/contents';
import { login } from './../src/redux/reducers/login';
import { tags } from './../src/redux/reducers/tags';
import { newPassword, userReducer } from './../src/redux/reducers/user';
import App from './components/App';
import "./index.scss";
import { courses } from './redux/reducers/courses';
import { email } from './redux/reducers/email';
import { mashie, mashieSubmissions } from "./redux/reducers/mashie";
import { modules } from './redux/reducers/modules';
import { product } from './redux/reducers/product';
import { purchase } from './redux/reducers/purchase';
import reportWebVitals from './reportWebVitals';

const reducers = combineReducers({
  userReducer, login, admins,
  tags, contents, content,
  newPassword, mashie, courses,
  modules, purchase, product, email, mashieSubmissions
});

const middleware = [thunk];
if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}
const store = createStore(reducers, applyMiddleware(...middleware));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
